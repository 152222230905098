import {
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_RESET,
  USER_LOGOUT_FAIL,
  USER_SSO_REQUEST,
  USER_SSO_SUCCESS,
  USER_SSO_RESET,
  USER_SSO_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  FORGOT_PASSWORD_RESET,
  USER_LOGIN_RESET,
  MAIL_RESEND_SUCCESS,
  MAIL_RESEND_FAIL,
  MAIL_RESEND_RESET,
  TOKEN_GENERATE_SUCCESS,
  TOKEN_GENERATE_FAIL,
  TOKEN_GENERATE_RESET,
  CHECKEMAIL_SUCCESS,
  CHECKEMAIL_FAIL,
  CHECKEMAIL_RESET
} from "../Constants/authConstants";


export const checkMailReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKEMAIL_SUCCESS:
      return { loading: false, data: action.payload };
    case CHECKEMAIL_FAIL:
      return { loading: false, error: action.payload };
    case CHECKEMAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const jwtGenerateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOKEN_GENERATE_SUCCESS:
      return { loading: false, data: action.payload };
    case TOKEN_GENERATE_FAIL:
      return { loading: false, error: action.payload };
    case TOKEN_GENERATE_RESET:
      return {};
    default:
      return state;
  }
};

export const resendMailReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIL_RESEND_SUCCESS:
      return { loading: false, data: action.payload };
    case MAIL_RESEND_FAIL:
      return { loading: false, error: action.payload };
    case MAIL_RESEND_RESET:
      return {};
    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return { loading: true };
    case FORGOT_PASSWORD_SUCCESS:
      return { loading: false, password: action.payload };
    case FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case FORGOT_PASSWORD_RESET:
      return { loading: false, password: "" };
    default:
      return state;
  }
};

export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { loading: true };
    case RESET_PASSWORD_SUCCESS:
      return { loading: false, data: action.payload };
    case RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userSocialReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SSO_REQUEST:
      return { loading: true };
    case USER_SSO_SUCCESS:
      return { loading: false, social: action.payload };
    case USER_SSO_FAIL:
      return { loading: false, error: action.payload };
    case USER_SSO_RESET:
      return { loading: false, social: {} };
    default:
      return state;
  }
};

export const userLogOutReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGOUT_REQUEST:
      return { loading: true };
    case USER_LOGOUT_SUCCESS:
      return { loading: false, loginInfo: action.payload };
    case USER_LOGOUT_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT_RESET:
      return { loading: false, loginInfo: {} };
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, loginInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_RESET:
        return { loading: false, loginInfo: {} };
    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGIN_RESET:
      return { loading: false, userInfo: {} };
    case USER_LOGOUT:
      return { loading: false, userInfo: {} };
    default:
      return state;
  }
};
