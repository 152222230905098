import {
  GET_RESUME_REQUEST,
  GET_RESUME_SUCCESS,
  GET_RESUME_FAIL,
  RESUME_CREATE_RESET,
  GET_RESUMEBYID_REQUEST,
  GET_RESUMEBYID_SUCCESS,
  GET_RESUMEBYID_FAIL,
  GET_RESUMEBYID_RESET,
  RESUME_DUPLICATE_REQUEST,
  RESUME_DUPLICATE_SUCCESS,
  RESUME_DUPLICATE_FAIL,
  RESUME_DUPLICATE_RESET,
  RESUME_CREATE_REQUEST,
  RESUME_CREATE_SUCCESS,
  RESUME_CREATE_FAIL,
  RESUME_DELETE_REQUEST,
  RESUME_DELETE_SUCCESS,
  RESUME_DELETE_FAIL,
  RESUME_DELETE_RESET,
  RESUME_UPDATE_REQUEST,
  RESUME_UPDATE_SUCCESS,
  RESUME_UPDATE_FAIL,
  RESUME_UPDATE_RESET,
  RESUME_DOWNLOAD_REQUEST,
  RESUME_DOWNLOAD_SUCCESS,
  RESUME_DOWNLOAD_FAIL,
  RESUME_DUPLICATE_TITLE_REQUEST,
  RESUME_DUPLICATE_TITLE_SUCCESS,
  RESUME_DUPLICATE_TITLE_FAIL,
  RESUME_DUPLICATE_TITLE_RESET,
  RESUME_NAME_CHANGE_REQUEST,
  RESUME_NAME_CHANGE_SUCCESS,
  RESUME_NAME_CHANGE_FAIL,
  RESUME_LAST_UPDATED_AT,
  RESUME_PREVIEW_REQUEST,
  RESUME_PREVIEW_SUCCESS,
  RESUME_PREVIEW_FAIL,
  RESUME_PREVIEW_RESET,
  ADMIN_LIST_SUCCESS,
  ADMIN_LIST_FAIL,
  ADMIN_LIST_REQUEST,
  RESUME_IMAGE_REQUEST,
  RESUME_IMAGE_SUCCESS,
  RESUME_IMAGE_FAIL,
  RESUME_DATA_UPDATE_SUCCESS,
  TEMP_RESUME_CREATE_SUCCESS,
  TEMP_RESUME_CREATE_FAIL,
  TEMP_RESUME_GET_SUCCESS,
  TEMP_RESUME_GET_FAIL,
  GET_RESUME_HISTORY_SUCCESS,
  GET_RESUME_HISTORY_FAIL,
  RESUME_UPDATETOREDIS_REQUEST,
  RESUME_UPDATETOREDIS_SUCCESS,
  RESUME_UPDATETOREDIS_FAIL
} from "../Constants/ResumeConstants";
import {
  EDITOR_DATA_ADD,
  EDITOR_DATA_RESET,
} from "../Constants/EditorConstants";
const initialState = {};

export const resumeGetHistoryReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESUME_HISTORY_SUCCESS:
      return { data: action.payload };
    case GET_RESUME_HISTORY_FAIL:
      return { data: action.payload };
    default:
      return state;
  }
};

export const resumeUpdateToRedisReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_UPDATETOREDIS_REQUEST:
      return { loading: action.loading };
    case RESUME_UPDATETOREDIS_SUCCESS:
      return { data: action.payload.data };
    case RESUME_UPDATETOREDIS_FAIL:
      return { data: action.payload };
    default:
      return state;
  }
};

export const tempResumeGetReducers = (state = initialState, action) => {
  switch (action.type) {
    case TEMP_RESUME_GET_SUCCESS:
      return { data: action.payload };
    case TEMP_RESUME_GET_FAIL:
      return { data: action.payload };
    default:
      return state;
  }
};

export const tempResumeReducers = (state = initialState, action) => {
  switch (action.type) {
    case TEMP_RESUME_CREATE_SUCCESS:
      return { data: action.payload };
    case TEMP_RESUME_CREATE_FAIL:
      return { data: action.payload };
    default:
      return state;
  }
};

export const editorDataReducers = (state = initialState, action) => {
  switch (action.type) {
    case EDITOR_DATA_ADD:
      return action.payload;
    case EDITOR_DATA_RESET:
      return {};
    default:
      return state;
  }
};

export const resumePreviewImageReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_IMAGE_REQUEST:
      return { loading: action.loading };
    case RESUME_IMAGE_SUCCESS:
      return { data: action.payload.data };
    case RESUME_IMAGE_FAIL:
      return { data: action.payload };
    default:
      return state;
  }
};

export const resumeDataUpdateReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case RESUME_DATA_UPDATE_SUCCESS:
      return { data: action.payload };
    default:
      return state;
  }
};

export const adminListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LIST_REQUEST:
      return { loading: action.loading };
    case ADMIN_LIST_SUCCESS:
      return { data: action.payload.data };
    case ADMIN_LIST_FAIL:
      return { data: action.payload };
    default:
      return state;
  }
};

export const resumePreviewReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_PREVIEW_REQUEST:
      return { loading: action.loading };
    case RESUME_PREVIEW_SUCCESS:
      return { data: action.payload.data };
    case RESUME_PREVIEW_FAIL:
      return { error: action.payload };
    case RESUME_PREVIEW_RESET:
      return { data: {} };
    default:
      return state;
  }
};

export const resumeNameChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_NAME_CHANGE_REQUEST:
      return { loading: action.loading };
    case RESUME_NAME_CHANGE_SUCCESS:
      return { data: action.payload };
    case RESUME_NAME_CHANGE_FAIL:
      return { data: action.payload };
    default:
      return state;
  }
};

export const resumeDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_DOWNLOAD_REQUEST:
      return { loading: action.loading };
    case RESUME_DOWNLOAD_SUCCESS:
      return { data: action.payload };
    case RESUME_DOWNLOAD_FAIL:
      return { data: action.payload };
    default:
      return state;
  }
};

export const createResumeReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_CREATE_REQUEST:
      return { loading: action.loading };
    case RESUME_CREATE_SUCCESS:
      return { status: true, create: action.payload.data };
    case RESUME_CREATE_FAIL:
      return { data: action.payload };
    case RESUME_CREATE_RESET:
      return { loading: false, create: {} };
    default:
      return state;
  }
};

export const resumeupdateReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_UPDATE_REQUEST:
      return { loading: action.loading };
    case RESUME_UPDATE_SUCCESS:
      return { loading: action.loading, data: action.payload };
    case RESUME_UPDATE_FAIL:
      return { loading: action.loading, data: action.payload };
    case RESUME_UPDATE_RESET:
      return { loading: action.loading, data: {} };
    default:
      return state;
  }
};

export const resumeGetReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RESUME_REQUEST:
      return { loading: action.loading };
    case GET_RESUME_SUCCESS:
      return { loading: action.loading, resumeList: action.payload };
    case GET_RESUME_FAIL:
      return { loading: action.loading, data: action.payload };
    default:
      return state;
  }
};

export const resumeGetByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RESUMEBYID_REQUEST:
      return { loading: action.loading };
    case GET_RESUMEBYID_SUCCESS:
      return { loading: false, resume: action.payload.data };
    case GET_RESUMEBYID_FAIL:
      return { loading: action.loading, resume: action.payload };
    case GET_RESUMEBYID_RESET:
      return { loading: action.loading, resume: {} };
    default:
      return state;
  }
};

export const duplicateResumeReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_DUPLICATE_REQUEST:
      return { loading: action.loading };
    case RESUME_DUPLICATE_SUCCESS:
      return { loading: action.loading, data: action.payload.data };
    case RESUME_DUPLICATE_FAIL:
      return { loading: action.loading, data: action.payload };
    case RESUME_DUPLICATE_RESET:
      return { loading: action.loading, data: {} };
    default:
      return state;
  }
};

export const deleteResumeReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_DELETE_REQUEST:
      return { loading: action.loading };
    case RESUME_DELETE_SUCCESS:
      return { loading: action.loading, data: action.payload };
    case RESUME_DELETE_FAIL:
      return { loading: action.loading, data: action.payload };
    case RESUME_DELETE_RESET:
      return { loading: action.loading, data: action.payload };
    default:
      return state;
  }
};

export const duplicateTitleReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_DUPLICATE_TITLE_REQUEST:
      return { loading: action.loading };
    case RESUME_DUPLICATE_TITLE_SUCCESS:
      return { loading: action.loading, data: action.payload };
    case RESUME_DUPLICATE_TITLE_FAIL:
      return { loading: action.loading, data: action.payload };
    case RESUME_DUPLICATE_TITLE_RESET:
      return { loading: action.loading, data: {} };
    default:
      return state;
  }
};

export const resumeLastUpdatedAtReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_LAST_UPDATED_AT:
      return {
        date: action.payload.data.updated_at,
        title: action.payload.data.title,
      };
    default:
      return state;
  }
};
