import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import common_en from "./translations/en/common.json";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      common: common_en,
    },
  },
});
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// ReactDOM.render(
//   <React.StrictMode>
//     <I18nextProvider i18n={i18next}>
//       {/* <Elements stripe={stripePromise}> */}
//         <App />
//       {/* </Elements> */}
//     </I18nextProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </I18nextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
