import {
  LANGUAGES_ADD,
  LANGUAGES_UPDATE,
  SAMPLE_LANGUAGES_ADD,
  SAMPLE_LANGUAGES_UPDATE,
} from "../Constants/EditorConstants";

import { languages } from "../../masterData";

export const LanguagesReducers = (state = languages, action) => {
  switch (action.type) {
    case LANGUAGES_ADD:
      return action.payload;
    case LANGUAGES_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

export const sampleLanguagesReducers = (state = languages, action) => {
  switch (action.type) {
    case SAMPLE_LANGUAGES_ADD:
      return action.payload;
    case SAMPLE_LANGUAGES_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
