import {
  SAMPLE_ADD_REQUEST,
  SAMPLE_ADD_SUCCESS,
  SAMPLE_ADD_FAIL,
  SAMPLE_ADD_RESET,
  SAMPLE_LIST_REQUEST,
  SAMPLE_LIST_SUCCESS,
  SAMPLE_LIST_FAIL,
  SAMPLE_PARTICULAR_REQUEST,
  SAMPLE_PARTICULAR_SUCCESS,
  SAMPLE_PARTICULAR_FAILED,
  SAMPLE_EDIT_SUCCESS,
  SAMPLE_EDIT_FAIL,
  SAMPLE_EDIT_REQUEST,
  SAMPLE_EDIT_RESET,
  SAMPLE_DELETE_SUCCESS,
  SAMPLE_DELETE_FAIL,
  SAMPLE_DELETE_REQUEST,
  SAMPLE_LISTFRONT_REQUEST,
  SAMPLE_LISTFRONT_SUCCESS,
  SAMPLE_LISTFRONT_FAIL,
  SAMPLE_PREVIEW_REQUEST,
  SAMPLE_PREVIEW_SUCCESS,
  SAMPLE_PREVIEW_FAIL,
  SAMPLE_GENERATE_REQUEST,
  SAMPLE_GENERATE_SUCCESS,
  SAMPLE_GENERATE_FAIL,
  SAMPLE_COUNT_SUCCESS,
  SAMPLE_COUNT_FAILED,
  GETEXPERIENCE_SUCCESS,
  GETEXPERIENCE_FAIL,
  COVER_SAMPLE_LIST_REQUEST,
  COVER_SAMPLE_LIST_SUCCESS,
  COVER_SAMPLE_LIST_FAIL,
} from "../Constants/SampleConstatns";

export const coverSampleListReducer = (state = {}, action) => {
  switch (action.type) {
    case COVER_SAMPLE_LIST_REQUEST:
      return { loading: true };
    case COVER_SAMPLE_LIST_SUCCESS:
      return { loading: false, sample: action.payload };
    case COVER_SAMPLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getExperienceReducer = (state = {}, action) => {
  switch (action.type) {
    case GETEXPERIENCE_SUCCESS:
      return { loading: false, experience: action.payload };
    case GETEXPERIENCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sampleCountReducer = (state = {}, action) => {
  switch (action.type) {
    case SAMPLE_COUNT_SUCCESS:
      return { loading: false, sample: action.payload };
    case SAMPLE_COUNT_FAILED:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sampleFrontReducer = (state = {}, action) => {
  switch (action.type) {
    case SAMPLE_LISTFRONT_REQUEST:
      return { loading: true };
    case SAMPLE_LISTFRONT_SUCCESS:
      return { loading: false, sample: action.payload };
    case SAMPLE_LISTFRONT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const samplePreviewReducer = (state = {}, action) => {
  switch (action.type) {
    case SAMPLE_PREVIEW_REQUEST:
      return { loading: true };
    case SAMPLE_PREVIEW_SUCCESS:
      return { loading: false, sample: action.payload };
    case SAMPLE_PREVIEW_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sampleDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SAMPLE_DELETE_REQUEST:
      return { loading: true };
    case SAMPLE_DELETE_SUCCESS:
      return { loading: false, sample: action.payload };
    case SAMPLE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sampleEditReducer = (state = {}, action) => {
  switch (action.type) {
    case SAMPLE_EDIT_REQUEST:
      return { loading: true };
    case SAMPLE_EDIT_SUCCESS:
      return { loading: false, sample: action.payload };
    case SAMPLE_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case SAMPLE_EDIT_RESET:
      return { loading: false, sample: {} };
    default:
      return state;
  }
};
export const sampleAddReducer = (state = {}, action) => {
  switch (action.type) {
    case SAMPLE_ADD_REQUEST:
      return { loading: true };
    case SAMPLE_ADD_SUCCESS:
      return { loading: false, sample: action.payload };
    case SAMPLE_ADD_FAIL:
      return { loading: false, error: action.payload };
    case SAMPLE_ADD_RESET:
      return { loading: false, sample: {} };
    default:
      return state;
  }
};

export const sampleParticularReducer = (state = {}, action) => {
  switch (action.type) {
    case SAMPLE_PARTICULAR_REQUEST:
      return { loading: true };
    case SAMPLE_PARTICULAR_SUCCESS:
      return { loading: false, sample: action.payload };
    case SAMPLE_PARTICULAR_FAILED:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sampleListReducer = (state = {}, action) => {
  switch (action.type) {
    case SAMPLE_LIST_REQUEST:
      return { loading: true };
    case SAMPLE_LIST_SUCCESS:
      return { loading: false, sample: action.payload };
    case SAMPLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
