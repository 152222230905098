export const SUGGESTION_ADD_REQUEST = "SUGGESTION_ADD_REQUEST";
export const SUGGESTION_ADD_SUCCESS = "SUGGESTION_ADD_SUCCESS";
export const SUGGESTION_ADD_FAIL = "SUGGESTION_ADD_FAIL";
export const SUGGESTION_ADD_RESET = "SUGGESTION_ADD_RESET";


export const SUGGESTION_LIST_REQUEST = "SUGGESTION_LIST_REQUEST";
export const SUGGESTION_LIST_SUCCESS = "SUGGESTION_LIST_SUCCESS";
export const SUGGESTION_LIST_FAIL = "SUGGESTION_LIST_FAIL";

export const SUGGESTION_PARTICULAR_REQUEST = "SUGGESTION_PARTICULAR_REQUEST";
export const SUGGESTION_PARTICULAR_SUCCESS = "SUGGESTION_PARTICULAR_SUCCESS";
export const SUGGESTION_PARTICULAR_FAILED = "SUGGESTION_PARTICULAR_FAILED";

export const SUGGESTION_EDIT_SUCCESS = "SUGGESTION_EDIT_SUCCESS";
export const SUGGESTION_EDIT_FAIL = "SUGGESTION_EDIT_FAIL";
export const SUGGESTION_EDIT_RESET = "SUGGESTION_EDIT_RESET";
export const SUGGESTION_EDIT_REQUEST = "SUGGESTION_EDIT_REQUEST";

export const SUGGESTION_DELETE_REQUEST = "SUGGESTION_DELETE_REQUEST";
export const SUGGESTION_DELETE_SUCCESS = "SUGGESTION_DELETE_SUCCESS";
export const SUGGESTION_DELETE_FAIL = "SUGGESTION_DELETE_FAIL";

export const SUGGESTION_LISTFRONT_REQUEST = "SUGGESTION_LISTFRONT_REQUEST";
export const SUGGESTION_LISTFRONT_SUCCESS = "SUGGESTION_LISTFRONT_SUCCESS";
export const SUGGESTION_LISTFRONT_FAIL = "SUGGESTION_LISTFRONT_FAIL";
