import React from "react";

function Spinner() {
  const spinnerStyle = {
    maxWidth: "70px",
    position: "sticky",
    top: "50vh",
    left: "50%",
    transform: "translate(-50%,-50%)",
    borderRadius: "6px",
    // boxShadow: "0 2px 5px 0 rgb(0 0 0 / 6%)",
    zIndex: "9999",
  };

  const spinnerDivStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "9999",
    // borderRadius: "6px",
    // boxShadow: "0 2px 5px 0 rgb(0 0 0 / 6%)",
    // zIndex: "999",
  };
  return (
    <div style={spinnerDivStyle}>
      <img src="/images/loading-2.gif" style={spinnerStyle} alt="loading" />
    </div>
  );
}

export default Spinner;
