import { useState, createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { templateGetActions } from "../Store/Actions/TemplateActions";
import { covertemplateGetActions } from "../Store/Actions/TemplateActions";
// import { jwtGenerateForUnauthenticated } from "../Store/Actions/authActions";
export const ExternalUserContext = createContext(null);

export const ExternalUserProvider = (props) => {
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const loadUserData = useSelector((state) => state.userData.userInfo);
  const dummyToken = useSelector((state) => state.jwtGenerate);
  const path = window.location.pathname;
  useEffect(() => {
    if (loadUserData && loadUserData.token) {
      setIsLogin(true);
      // dispatch(templateGetActions(false));
      // dispatch(covertemplateGetActions(false));
    }
  }, [loadUserData]);
  // useEffect(() => {
  //   if (
  //     dummyToken &&
  //     dummyToken.data &&
  //     dummyToken.data.token &&
  //     !loadUserData
  //   ) {
  //     dispatch(templateGetActions(false));
  //     dispatch(covertemplateGetActions(false));
  //   }
  // }, [dummyToken]);

  // useEffect(() => {
  //   if (loadUserData && loadUserData.token) {
  //   } else if (dummyToken && dummyToken.data && dummyToken.data.token) {
  //   } else {
  //     if(path&&!path.includes('onboarding')){
  //       dispatch(jwtGenerateForUnauthenticated(false));
  //     }
  //   }
  // }, [loadUserData]);

  return (
    <ExternalUserContext.Provider
      value={{
        isLogin,
        setIsLogin,
      }}
    >
      {props.children}
    </ExternalUserContext.Provider>
  );
};
