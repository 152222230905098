import {
  UNDOREDO_ADD,
  EDITOR_DELETE,
  UNDOREDO_ADD_COVER,
} from "../Constants/EditorConstants";
const initialState = {
  history: {
    timeline: [],
    currentIndex: 0,
  },
};
export const undoRedoReducers = (state = initialState, action) => {
  switch (action.type) {
    case UNDOREDO_ADD:
      return action.payload;
    default:
      return state;
  }
};

export const undoRedoCoverReducers = (state = initialState, action) => {
  switch (action.type) {
    case UNDOREDO_ADD_COVER:
      return action.payload;
    default:
      return state;
  }
};
