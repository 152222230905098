export const PROFILE_ADD = "PROFILE_ADD";
export const PROFILE_UPDATE = "PROFILE_UPDATE";

export const SUMMARY_ADD = "SUMMARY_ADD";
export const SUMMARY_UPDATE = "SUMMARY_UPDATE";

export const WORK_EXPERIENCE_ADD = "WORK_EXPERIENCE_ADD";
export const WORK_EXPERIENCE_UPDATE = "WORK_EXPERIENCE_UPDATE";

export const EDUCATION_ADD = "EDUCATION_ADD";
export const EDUCATION_UPDATE = "EDUCATION_UPDATE";

export const SKILLS_ADD = "SKILLS_ADD";
export const SKILLS_UPDATE = "SKILLS_UPDATE";

export const UNDOREDO_ADD = "UNDOREDO_ADD";

export const UNDOREDO_ADD_COVER = "UNDOREDO_ADD_COVER";

export const ACHIEVEMENTS_ADD = "ACHIEVEMENTS_ADD";
export const ACHIEVEMENTS_UPDATE = "ACHIEVEMENTS_UPDATE";

export const LANGUAGES_ADD = "LANGUAGES_ADD";
export const LANGUAGES_UPDATE = "LANGUAGES_UPDATE";

export const FROM_ADD = "FROM_ADD";
export const FROM_UPDATE = "FROM_UPDATE";

export const TO_ADD = "TO_ADD";
export const TO_UPDATE = "TO_UPDATE";

export const CONTENT_ADD = "CONTENT_ADD";
export const CONTENT_UPDATE = "CONTENT_UPDATE";

export const INTEREST_ADD = "INTEREST_ADD";
export const INTEREST_UPDATE = "INTEREST_UPDATE";

export const CUSTOM_TEXT_SECTION_ADD = "CUSTOM_TEXT_SECTION_ADD";
export const CUSTOM_TEXT_SECTION_UPTEXT = "CUSTOM_TEXT_SECTION_UPTEXT";

export const EDITOR_DELETE = "EDITOR_DELETE";

export const POSITION_ADD = "POSITION_ADD";

export const EDITOR_DATA_ADD = "EDITOR_DATA_ADD";
export const EDITOR_DATA_RESET = "EDITOR_DATA_RESET";

export const COVER_DATA_ADD = "COVER_DATA_ADD";
export const COVER_DATA_RESET = "COVER_DATA_RESET";

export const COVERJOB_ISPRESENT_REQUEST = "COVERJOB_ISPRESENT_REQUEST";
export const COVERJOB_ISPRESENT_SUCCESS = "COVERJOB_ISPRESENT_SUCCESS";
export const COVERJOB_ISPRESENT_FAIL = "COVERJOB_ISPRESENT_FAIL";
export const COVERJOB_ISPRESENT_RESET = "COVERJOB_ISPRESENT_RESET";

export const LAYOUT_ADD = "LAYOUT_ADD";
export const CALCULATE_HEIGHT = "CALCULATE_HEIGHT";
export const SAMPLE_LAYOUT_ADD = "SAMPLE_LAYOUT_ADD";

export const SETTINGS_ADD = "SETTINGS_ADD";
export const SETTINGS_UPDATE = "SETTINGS_UPDATE";

export const JOB_ISPRESENT_REQUEST = "JOB_ISPRESENT_REQUEST";
export const JOB_ISPRESENT_SUCCESS = "JOB_ISPRESENT_SUCCESS";
export const JOB_ISPRESENT_FAIL = "JOB_ISPRESENT_FAIL";
export const JOB_ISPRESENT_RESET = "JOB_ISPRESENT_RESET";

export const SAMPLE_SETTINGS_ADD = "SAMPLE_SETTINGS_ADD";
export const SAMPLE_SETTINGS_UPDATE = "SAMPLE_SETTINGS_UPDATE";

export const SAMPLE_PROFILE_ADD = "SAMPLE_PROFILE_ADD";
export const SAMPLE_PROFILE_UPDATE = "SAMPLE_PROFILE_UPDATE";

export const SAMPLE_SUMMARY_ADD = "SAMPLE_SUMMARY_ADD";
export const SAMPLE_SUMMARY_UPDATE = "SAMPLE_SUMMARY_UPDATE";

export const SAMPLE_WORK_EXPERIENCE_ADD = "SAMPLE_WORK_EXPERIENCE_ADD";
export const SAMPLE_WORK_EXPERIENCE_UPDATE = "SAMPLE_WORK_EXPERIENCE_UPDATE";

export const SAMPLE_EDUCATION_ADD = "SAMPLE_EDUCATION_ADD";
export const SAMPLE_EDUCATION_UPDATE = "SAMPLE_EDUCATION_UPDATE";

export const SAMPLE_SKILLS_ADD = "SAMPLE_SKILLS_ADD";
export const SAMPLE_SKILLS_UPDATE = "SAMPLE_SKILLS_UPDATE";

export const SAMPLE_ACHIEVEMENTS_ADD = "SAMPLE_ACHIEVEMENTS_ADD";
export const SAMPLE_ACHIEVEMENTS_UPDATE = "SAMPLE_ACHIEVEMENTS_UPDATE";

export const SAMPLE_LANGUAGES_ADD = "SAMPLE_LANGUAGES_ADD";
export const SAMPLE_LANGUAGES_UPDATE = "SAMPLE_LANGUAGES_UPDATE";

export const SAMPLE_FROM_ADD = "SAMPLE_FROM_ADD";
export const SAMPLE_FROM_UPDATE = "SAMPLE_FROM_UPDATE";

export const SAMPLE_TO_ADD = "SAMPLE_TO_ADD";
export const SAMPLE_TO_UPDATE = "SAMPLE_TO_UPDATE";

export const SAMPLE_CONTENT_ADD = "SAMPLE_CONTENT_ADD";
export const SAMPLE_CONTENT_UPDATE = "SAMPLE_CONTENT_UPDATE";

export const SAMPLE_INTEREST_ADD = "SAMPLE_INTEREST_ADD";
export const SAMPLE_INTEREST_UPDATE = "SAMPLE_INTEREST_UPDATE";

export const SAMPLE_CUSTOM_DATE_SECTION_ADD = "SAMPLE_CUSTOM_DATE_SECTION_ADD";
export const SAMPLE_CUSTOM_DATE_SECTION_UPDATE =
  "SAMPLE_CUSTOM_DATE_SECTION_UPDATE";

export const SAMPLE_CUSTOM_TEXT_SECTION_ADD = "SAMPLE_CUSTOM_TEXT_SECTION_ADD";
export const SAMPLE_CUSTOM_TEXT_SECTION_UPTEXT =
  "SAMPLE_CUSTOM_TEXT_SECTION_UPTEXT";

export const SAMPLE_EDITOR_DELETE = "SAMPLE_EDITOR_DELETE";

export const SAMPLE_POSITION_ADD = "SAMPLE_POSITION_ADD";

export const SAMPLE_COLORCHANGE_ADD = "SAMPLE_COLORCHANGE_ADD";

export const CUSTOM_ONE_DATE_SECTION_UPDATE = "CUSTOM_ONE_DATE_SECTION_UPDATE";

export const CUSTOM_ONE_DATE_SECTION_ADD = "CUSTOM_ONE_DATE_SECTION_ADD";
export const CUSTOM_TWO_DATE_SECTION_ADD = "CUSTOM_TWO_DATE_SECTION_ADD";
export const CUSTOM_THREE_DATE_SECTION_ADD = "CUSTOM_THREE_DATE_SECTION_ADD";
export const CUSTOM_FOUR_DATE_SECTION_ADD = "CUSTOM_FOUR_DATE_SECTION_ADD";
export const CUSTOM_FIVE_DATE_SECTION_ADD = "CUSTOM_FIVE_DATE_SECTION_ADD";
export const CUSTOM_SIX_DATE_SECTION_ADD = "CUSTOM_SIX_DATE_SECTION_ADD";
export const CUSTOM_SEVEN_DATE_SECTION_ADD = "CUSTOM_SEVEN_DATE_SECTION_ADD";
export const CUSTOM_EIGHT_DATE_SECTION_ADD = "CUSTOM_EIGHT_DATE_SECTION_ADD";
export const CUSTOM_NINE_DATE_SECTION_ADD = "CUSTOM_NINE_DATE_SECTION_ADD";
export const CUSTOM_TEN_DATE_SECTION_ADD = "CUSTOM_TEN_DATE_SECTION_ADD";
export const CUSTOM_ELEVEN_DATE_SECTION_ADD = "CUSTOM_ELEVEN_DATE_SECTION_ADD";
export const CUSTOM_TWELVE_DATE_SECTION_ADD = "CUSTOM_TWELVE_DATE_SECTION_ADD";
export const CUSTOM_THIRTEEN_DATE_SECTION_ADD =
  "CUSTOM_THIRTEEN_DATE_SECTION_ADD";
export const CUSTOM_FOURTEEN_DATE_SECTION_ADD =
  "CUSTOM_FOURTEEN_DATE_SECTION_ADD";
export const CUSTOM_FIFTEEN_DATE_SECTION_ADD =
  "CUSTOM_FIFTEEN_DATE_SECTION_ADD";
export const CUSTOM_SIXTEEN_DATE_SECTION_ADD =
  "CUSTOM_SIXTEEN_DATE_SECTION_ADD";
export const CUSTOM_SEVENTEEN_DATE_SECTION_ADD =
  "CUSTOM_SEVENTEEN_DATE_SECTION_ADD";
export const CUSTOM_EIGHTEEN_DATE_SECTION_ADD =
  "CUSTOM_EIGHTEEN_DATE_SECTION_ADD";
export const CUSTOM_NINETEEN_DATE_SECTION_ADD =
  "CUSTOM_NINETEEN_DATE_SECTION_ADD";
export const CUSTOM_TWENTY_DATE_SECTION_ADD = "CUSTOM_TWENTY_DATE_SECTION_ADD";

export const CUSTOM_ONE_TEXT_SECTION_ADD = "CUSTOM_ONE_TEXT_SECTION_ADD";
export const CUSTOM_TWO_TEXT_SECTION_ADD = "CUSTOM_TWO_TEXT_SECTION_ADD";
export const CUSTOM_THREE_TEXT_SECTION_ADD = "CUSTOM_THREE_TEXT_SECTION_ADD";
export const CUSTOM_FOUR_TEXT_SECTION_ADD = "CUSTOM_FOUR_TEXT_SECTION_ADD";
export const CUSTOM_FIVE_TEXT_SECTION_ADD = "CUSTOM_FIVE_TEXT_SECTION_ADD";
export const CUSTOM_SIX_TEXT_SECTION_ADD = "CUSTOM_SIX_TEXT_SECTION_ADD";
export const CUSTOM_SEVEN_TEXT_SECTION_ADD = "CUSTOM_SEVEN_TEXT_SECTION_ADD";
export const CUSTOM_EIGHT_TEXT_SECTION_ADD = "CUSTOM_EIGHT_TEXT_SECTION_ADD";
export const CUSTOM_NINE_TEXT_SECTION_ADD = "CUSTOM_NINE_TEXT_SECTION_ADD";
export const CUSTOM_TEN_TEXT_SECTION_ADD = "CUSTOM_TEN_TEXT_SECTION_ADD";
export const CUSTOM_ELEVEN_TEXT_SECTION_ADD = "CUSTOM_ELEVEN_TEXT_SECTION_ADD";
export const CUSTOM_TWELVE_TEXT_SECTION_ADD = "CUSTOM_TWELVE_TEXT_SECTION_ADD";
export const CUSTOM_THIRTEEN_TEXT_SECTION_ADD =
  "CUSTOM_THIRTEEN_TEXT_SECTION_ADD";
export const CUSTOM_FOURTEEN_TEXT_SECTION_ADD =
  "CUSTOM_FOURTEEN_TEXT_SECTION_ADD";
export const CUSTOM_FIFTEEN_TEXT_SECTION_ADD =
  "CUSTOM_FIFTEEN_TEXT_SECTION_ADD";
export const CUSTOM_SIXTEEN_TEXT_SECTION_ADD =
  "CUSTOM_SIXTEEN_TEXT_SECTION_ADD";
export const CUSTOM_SEVENTEEN_TEXT_SECTION_ADD =
  "CUSTOM_SEVENTEEN_TEXT_SECTION_ADD";
export const CUSTOM_EIGHTEEN_TEXT_SECTION_ADD =
  "CUSTOM_EIGHTEEN_TEXT_SECTION_ADD";
export const CUSTOM_NINETEEN_TEXT_SECTION_ADD =
  "CUSTOM_NINETEEN_TEXT_SECTION_ADD";
export const CUSTOM_TWENTY_TEXT_SECTION_ADD = "CUSTOM_TWENTY_TEXT_SECTION_ADD";
