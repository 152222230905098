import { SUMMARY_ADD, SUMMARY_UPDATE, SAMPLE_SUMMARY_ADD, SAMPLE_SUMMARY_UPDATE } from "../Constants/EditorConstants";

const initialState = {
	showSection: true,
	fields: [
		{
			summaryText: "",
		},
	],
};

export const SummaryReducers = (state = initialState, action) => {
	switch (action.type) {
		case SUMMARY_ADD:
			return action.payload;
		case SUMMARY_UPDATE:
			return action.payload;
		default:
			return state;
	}
};

export const sampleSummaryReducers = (state = initialState, action) => {
	switch (action.type) {
		case SAMPLE_SUMMARY_ADD:
			return action.payload;
		case SAMPLE_SUMMARY_UPDATE:
			return action.payload;
		default:
			return state;
	}
};
