import { EDUCATION_ADD, EDUCATION_UPDATE, SAMPLE_EDUCATION_ADD, SAMPLE_EDUCATION_UPDATE } from "../Constants/EditorConstants";
import { education } from "../../masterData";


export const EducationReducers = (state = education, action) => {
	switch (action.type) {
		case EDUCATION_ADD:
			return action.payload;
		case EDUCATION_UPDATE:
			return action.payload;
		default:
			return state;
	}
};

export const sampleEducationReducers = (state = education, action) => {
	switch (action.type) {
		case SAMPLE_EDUCATION_ADD:
			return action.payload;
		case SAMPLE_EDUCATION_UPDATE:
			return action.payload;
		default:
			return state;
	}
};
