import {
  WORK_EXPERIENCE_ADD,
  WORK_EXPERIENCE_UPDATE,
  SAMPLE_WORK_EXPERIENCE_ADD,
  SAMPLE_WORK_EXPERIENCE_UPDATE,
} from "../Constants/EditorConstants";
import { experience } from "../../masterData";

export const WorkexperienceReducers = (state = {}, action) => {
  switch (action.type) {
    case WORK_EXPERIENCE_ADD:
      return action.payload;
    case WORK_EXPERIENCE_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

export const sampleWorkexperienceReducers = (state = experience, action) => {
  switch (action.type) {
    case SAMPLE_WORK_EXPERIENCE_ADD:
      return action.payload;
    case SAMPLE_WORK_EXPERIENCE_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
