import { useState, createContext, useEffect, useContext } from "react";
import { resumeEditActions } from "../Store/Actions/ResumeActions";
import { useDispatch, useSelector } from "react-redux";
import { jsonEditorContext } from "../Context/jsonEditorContext";

export const ResumeUpdateContext = createContext(null);

export const ResumeUpdateContextProvider = (props) => {
  const { isSample, setIsSample } = useContext(jsonEditorContext);

  const dispatch = useDispatch();
  const [resumeUpdated, setResumeUpdated] = useState("");
  const resume = useSelector((state) => state.resumeGetById.resume);
  const [sectionFocusData, setSectionFocusData] = useState("");
  //   const [visualEditorFocus, setVisualEditorFocus] = useState(false);

  useEffect(() => {
    if (!isSample && resumeUpdated != "") {
      //   resumePageUpdate(editorData);
    }
  }, [resumeUpdated]);

  const resumePageUpdate = (content) => {
    if (resume) {
      //   setResumeUpdated(resume);
      var body = { ...resume, content: JSON.stringify(content) };
      dispatch(resumeEditActions(resume.id, body));
    }
  };

  return (
    <ResumeUpdateContext.Provider
      value={{
        resumeUpdated,
        setResumeUpdated,
        sectionFocusData,
        setSectionFocusData,
        // visualEditorFocus,
        // setVisualEditorFocus,
      }}
    >
      {props.children}
    </ResumeUpdateContext.Provider>
  );
};
