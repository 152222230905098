import { CONTENT_ADD, CONTENT_UPDATE } from "../Constants/EditorConstants";
const initialState = {
  description: "",
};
export const contentReducers = (state = initialState, action) => {
  switch (action.type) {
    case CONTENT_ADD:
      return action.payload;
    case CONTENT_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
