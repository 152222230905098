import axios from "axios";
import {
  LOADING_REQUEST,
  LOADING_SUCCESS,
} from "../Constants/LoadingConstants";
import { updateLocal } from "../../Utils/updateLocalStorage";

import {
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_SSO_REQUEST,
  USER_SSO_SUCCESS,
  USER_SSO_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  MAIL_RESEND_SUCCESS,
  MAIL_RESEND_FAIL,
  TOKEN_GENERATE_SUCCESS,
  TOKEN_GENERATE_FAIL,
  CHECKEMAIL_SUCCESS,
  CHECKEMAIL_FAIL,
} from "../Constants/authConstants";

const baseUrl = process.env.REACT_APP_API_URL;

export const checkEmail = (email) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(
      `${baseUrl}/api/checkEmail/${email}`,
      config
    );

    dispatch({
      type: CHECKEMAIL_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: CHECKEMAIL_FAIL,
      payload: error.response && error.response.data ? error.response.data : "",
    });
  }
};

// export const jwtGenerateForUnauthenticated = (email) => async (dispatch) => {
//   try {
//     dispatch({
//       type: LOADING_REQUEST,
//       loading: true,
//     });
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };
//     const { data } = await axios.get(
//       `${baseUrl}/api/jwtGenerateForUnauthorised/?email=${email}`,
//       config
//     );
//     if (data && data.token) {
//       localStorage.setItem("guestToken", data && data.token);
//     }

//     dispatch({
//       type: TOKEN_GENERATE_SUCCESS,
//       payload: data,
//     });
//     dispatch({
//       type: LOADING_SUCCESS,
//       loading: false,
//     });
//   } catch (error) {
//     dispatch({
//       type: LOADING_SUCCESS,
//       loading: false,
//     });
//     dispatch({
//       type: TOKEN_GENERATE_FAIL,
//       payload: error.response && error.response.data ? error.response.data : "",
//     });
//   }
// };

export const resendEmail = (email) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`${baseUrl}/api/resend/${email}`, config);
    dispatch({
      type: MAIL_RESEND_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: MAIL_RESEND_FAIL,
      payload: error.response && error.response.data ? error.response.data : "",
    });
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/api/forgotpassword`,
      { email: email },
      config
    );
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response && error.response.data ? error.response.data : "",
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  }
};

export const resetPassword = (resetdata) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/api/resetpassword`,
      resetdata,
      config
    );
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response && error.response.data ? error.response.data : "",
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  }
};

export const ssoLogin = (type) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SSO_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`${baseUrl}/api/login/${type}`, config);
    dispatch({
      type: USER_SSO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_SSO_FAIL,
      payload: error.response && error.response.data ? error.response.data : "",
    });
  }
};

export const logout = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LOGOUT_REQUEST,
    });

    const {
      userData: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`${baseUrl}/api/logout`, {}, config);
    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LOGOUT,
      payload: data,
    });
    localStorage.removeItem("userInfo");
    localStorage.removeItem("guestToken");
    localStorage.removeItem("onboardingTemplate");
    document.cookie = `logged=false; path=/; secure; domain=qwikresume.com; expires=${new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 365
    ).toGMTString()}`;
    document.cookie = `sample=false; path=/; secure; domain=qwikresume.com; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
    document.cookie = `coverSample=false; path=/; secure; domain=qwikresume.com; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
    localStorage.removeItem("user_settings");
    const path = window.location.pathname;
    if (path && path.includes("premium")) {
      window.location.href = "/premium/onboarding";
    } else {
      window.location.href = "/login";
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_LOGOUT_FAIL,
      payload: message,
    });
  }
};

export const login =
  (account, secretCode, provider, reference) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });
      const { jwtGenerate } = getState();
      let token =
        jwtGenerate &&
        jwtGenerate.data &&
        jwtGenerate &&
        jwtGenerate.data.token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (account !== "" && !secretCode) {
        const { data } = await axios.post(
          `${baseUrl}/api/guestlogin`,
          { email: account },
          config
        );
        const userInfo = {
          token: data.token,
          user: data.data,
        };
        const user_settings = {
          job_title: data && data.data && data.data.job_title,
          job_category: data && data.data && data.data.job_category,
        };
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: userInfo,
        });
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.removeItem("guestToken");
        document.cookie = `logged=true; path=/; secure; domain=qwikresume.com; expires=${new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24 * 365
        ).toGMTString()}`;

        if (
          data &&
          data.data &&
          data.data.job_title &&
          data.data.job_title != ""
        ) {
          updateLocal(user_settings);
        }

        //   localStorage.setItem("user_settings", JSON.stringify(user_settings));
      } else if (provider) {
        const refer = localStorage.getItem("refer");
        const { data } = await axios.get(
          `${baseUrl}/api/login/${account}/callback/${secretCode}&refer=${refer}`,
          config
        );
        const userInfo = {
          token: data.token,
          user: data.data,
        };
        const user_settings = {
          job_title: data && data.data && data.data.job_title,
          job_category: data && data.data && data.data.job_category,
        };

        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: userInfo,
        });
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.removeItem("guestToken");
        document.cookie = `logged=true; path=/; secure; domain=qwikresume.com; expires=${new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24 * 365
        ).toGMTString()}`;

        if (
          data &&
          data.data &&
          data.data.job_title &&
          data.data.job_title != ""
        ) {
          updateLocal(user_settings);
        }

        //   localStorage.setItem("user_settings", JSON.stringify(user_settings));
      } else {
        const { data } = await axios.post(
          `${baseUrl}/api/login`,
          { email: account, password: secretCode },
          config
        );

        const userInfo = {
          token: data.token,
          user: data.data,
        };
        const user_settings = {
          job_title: data && data.data && data.data.job_title,
          job_category: data && data.data && data.data.job_category,
        };
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: userInfo,
        });
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.removeItem("guestToken");
        //The domain must match the domain of the JavaScript origin. Setting cookies to foreign domains will be silently ignored.
        //https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
        document.cookie = `logged=true; path=/; secure; domain=qwikresume.com; expires=${new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24 * 365
        ).toGMTString()}`;
        if (
          data &&
          data.data &&
          data.data.job_title &&
          data.data.job_title != ""
        ) {
          updateLocal(user_settings);
        }
      }
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const userRegister =
  (first_name, email, password, referrer) => async (dispatch) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });
      dispatch({
        type: USER_REGISTER_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const refer = localStorage.getItem("refer");
      const { data } = await axios.post(
        `${baseUrl}/api/register`,
        { first_name, email, password, refer },
        config
      );

      const userInfo = {
        token: data.token,
        user: data.data,
      };

      if (data && data.message) {
        dispatch({
          type: USER_REGISTER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: USER_REGISTER_SUCCESS,
          payload: userInfo,
        });
      }

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: userInfo,
      });

      //   localStorage.setItem("userInfo", JSON.stringify(userInfo));
      //   localStorage.removeItem("guestToken");
      document.cookie = `logged=true; path=/; secure; domain=qwikresume.com; expires=${new Date(
        new Date().getTime() + 1000 * 60 * 60 * 24 * 365
      ).toGMTString()}`;

      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data ? error.response.data : "",
      });
    }
  };
