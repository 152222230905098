import {
  POSITION_ADD,
  SAMPLE_POSITION_ADD,
} from "../Constants/EditorConstants";

const initialState = [
  {
    type: "profile",
    value: "profileSection",
  },
  {
    type: "education",
    value: "educationSection",
  },
  {
    type: "workexperience",
    value: "workexperienceSection",
  },
  {
    type: "summary",
    value: "summarySection",
  },
  {
    type: "skills",
    value: "skillsection",
  },
  {
    type: "languageknown",
    value: "languageKnownSection",
  },
  {
    type: "achievements",
    value: "achievementsSection",
  },
  {
    type: "interest",
    value: "interestSection",
  },
];

export const SectionPositionReducers = (state = [{}], action) => {
  switch (action.type) {
    case POSITION_ADD:
      return action.payload;
    default:
      return state;
  }
};

export const sampleSectionPositionReducers = (state = [{}], action) => {
  switch (action.type) {
    case SAMPLE_POSITION_ADD:
      return action.payload;
    default:
      return state;
  }
};
