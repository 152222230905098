import {
  PROFILE_ADD,
  PROFILE_UPDATE,
  SAMPLE_PROFILE_ADD,
  SAMPLE_PROFILE_UPDATE,
  JOB_ISPRESENT_REQUEST,
  JOB_ISPRESENT_SUCCESS,
  JOB_ISPRESENT_FAIL,
  JOB_ISPRESENT_RESET,
} from "../Constants/EditorConstants";

import { profile } from "../../masterData";

const isPresent = {
  data: false,
  isJob: false,
};

export const isJobPresentReducers = (state = isPresent, action) => {
  switch (action.type) {
    case JOB_ISPRESENT_REQUEST:
      return { loading: true };
    case JOB_ISPRESENT_SUCCESS:
      return action.payload.data;
    case JOB_ISPRESENT_FAIL:
      return { loading: false, error: action.payload };
    case JOB_ISPRESENT_RESET:
      return {};
    default:
      return state;
  }
};

export const ProfileReducers = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_ADD:
      return action.payload;
    case PROFILE_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

export const sampleProfileReducers = (state = profile, action) => {
  switch (action.type) {
    case SAMPLE_PROFILE_ADD:
      return action.payload;
    case SAMPLE_PROFILE_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
