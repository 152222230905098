import {
  ANALYZE_RESUME_SUCCESS,
  ANALYZE_RESUME_FAIL,
  ANALYZE_RESUME_RESET,
} from "../Constants/AnalyzeConstants";

export const analyzeResumeReducer = (state = {}, action) => {
  switch (action.type) {
    case ANALYZE_RESUME_SUCCESS:
      return { loading: false, data: action.payload };
    case ANALYZE_RESUME_FAIL:
      return { loading: false, error: action.payload };
    case ANALYZE_RESUME_RESET:
      return {};
    default:
      return state;
  }
};
