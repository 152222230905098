import axios from "axios";
import {
  FROM_ADD,
  FROM_UPDATE,
  EDITOR_DELETE,
  COVERJOB_ISPRESENT_REQUEST,
  COVERJOB_ISPRESENT_SUCCESS,
  COVERJOB_ISPRESENT_FAIL,
} from "../Constants/EditorConstants";
const baseUrl = process.env.REACT_APP_API_URL;

export const isCoverJobPresent = (jobTitle) => async (dispatch) => {
  try {
    dispatch({
      type: COVERJOB_ISPRESENT_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (typeof jobTitle == "undefined") {
      jobTitle = "";
    }

    const data = await axios.get(
      `${baseUrl}/api/coverjob/isPresent/${jobTitle}`,
      config
    );

    dispatch({
      type: COVERJOB_ISPRESENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COVERJOB_ISPRESENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fromAdd = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FROM_ADD,
      payload: { ...data },
    });
  } catch (error) {
    dispatch({
      type: EDITOR_DELETE,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const fromUpdate = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FROM_UPDATE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EDITOR_DELETE,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};
