export const PLAN_GET__REQUEST = "PLAN_GET__REQUEST";
export const PLAN_GET__SUCCESS = "PLAN_GET__SUCCESS";
export const PLAN_GET__FAIL = "PLAN_GET__FAIL";

export const DEFAULT_CHECKOUT_REQUEST = "DEFAULT_CHECKOUT_REQUEST";
export const DEFAULT_CHECKOUT_SUCCESS = "DEFAULT_CHECKOUT_SUCCESS";
export const DEFAULT_CHECKOUT_FAIL = "DEFAULT_CHECKOUT_FAIL";
export const DEFAULT_CHECKOUT_RESET = "DEFAULT_CHECKOUT_RESET";

export const PLAN_ADD_SUCCESS = "PLAN_ADD_SUCCESS";
export const PLAN_ADD_REQUEST = "PLAN_ADD_REQUEST";
export const PLAN_ADD_FAIL = "PLAN_ADD_FAIL";
export const PLAN_ADD_RESET = "PLAN_ADD_RESET";

export const GET_CLIENTSECRET_SUCCESS = "GET_CLIENTSECRET_SUCCESS";
export const GET_CLIENTSECRET_FAIL = "GET_CLIENTSECRET_FAIL";
export const GET_CLIENTSECRET_RESET = "GET_CLIENTSECRET_RESET";

export const UPDATE_USERPLAN_SUCCESS = "UPDATE_USERPLAN_SUCCESS";
export const UPDATE_USERPLAN_FAIL = "UPDATE_USERPLAN_FAIL";

export const PLAN_VISIT_SUCCESS = "PLAN_VISIT_SUCCESS";
export const PLAN_VISIT_FAIL = "PLAN_VISIT_FAIL";

export const PLAN_EDIT_SUCCESS = "PLAN_EDIT_SUCCESS";
export const PLAN_EDIT_REQUEST = "PLAN_EDIT_REQUEST";
export const PLAN_EDIT_FAILED = "PLAN_EDIT_FAILED";
export const PLAN_EDIT_RESET = "PLAN_EDIT_RESET";

export const PLAN_PARTICULAR_REQUEST = "PLAN_PARTICULAR_REQUEST";
export const PLAN_PARTICULAR_SUCCESS = "PLAN_PARTICULAR_SUCCESS";
export const PLAN_PARTICULAR_FAILED = "PLAN_PARTICULAR_FAILED";
export const PLAN_PARTICULAR_RESET = "PLAN_PARTICULAR_RESET";

export const PLAN_DELETE_REQUEST = "PLAN_DELETE_REQUEST";
export const PLAN_DELETE_SUCCESS = "PLAN_DELETE_SUCCESS";
export const PLAN_DELETE_FAIL = "PLAN_DELETE_FAIL";
export const PLAN_DELETE_RESET = "PLAN_DELETE_RESET";

export const PLAN_SHOW_REQUEST = "PLAN_SHOW_REQUEST";
export const PLAN_SHOW_SUCCESS = "PLAN_SHOW_SUCCESS";
export const PLAN_SHOW_FAIL = "PLAN_SHOW_FAIL";

export const PLAN_SUBMIT_REQUEST = "PLAN_SUBMIT_REQUEST";
export const PLAN_SUBMIT_SUCCESS = "PLAN_SUBMIT_SUCCESS";
export const PLAN_SUBMIT_FAIL = "PLAN_SUBMIT_FAIL";
export const PLAN_SUBMIT_RESET = "PLAN_SUBMIT_RESET";

export const PLAN_VALIDATE_REQUEST = "PLAN_VALIDATE_REQUEST";
export const PLAN_VALIDATE_SUCCESS = "PLAN_VALIDATE_SUCCESS";
export const PLAN_VALIDATE_FAIL = "PLAN_VALIDATE_FAIL";

export const USER_SUBSCRIPTION_REQUEST = "USER_SUBSCRIPTION_REQUEST";
export const USER_SUBSCRIPTION_SUCCESS = "USER_SUBSCRIPTION_SUCCESS";
export const USER_SUBSCRIPTION_FAIL = "USER_SUBSCRIPTION_FAIL";

export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAIL = "CANCEL_SUBSCRIPTION_FAIL";
