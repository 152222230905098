import {
  CUSTOM_ONE_DATE_SECTION_ADD,
  CUSTOM_TWO_DATE_SECTION_ADD,
  CUSTOM_THREE_DATE_SECTION_ADD,
  CUSTOM_FOUR_DATE_SECTION_ADD,
  CUSTOM_FIVE_DATE_SECTION_ADD,
  CUSTOM_SIX_DATE_SECTION_ADD,
  CUSTOM_SEVEN_DATE_SECTION_ADD,
  CUSTOM_EIGHT_DATE_SECTION_ADD,
  CUSTOM_NINE_DATE_SECTION_ADD,
  CUSTOM_TEN_DATE_SECTION_ADD,
  CUSTOM_ELEVEN_DATE_SECTION_ADD,
  CUSTOM_TWELVE_DATE_SECTION_ADD,
  CUSTOM_THIRTEEN_DATE_SECTION_ADD,
  CUSTOM_FOURTEEN_DATE_SECTION_ADD,
  CUSTOM_FIFTEEN_DATE_SECTION_ADD,
  CUSTOM_SIXTEEN_DATE_SECTION_ADD,
  CUSTOM_SEVENTEEN_DATE_SECTION_ADD,
  CUSTOM_EIGHTEEN_DATE_SECTION_ADD,
  CUSTOM_NINETEEN_DATE_SECTION_ADD,
  CUSTOM_TWENTY_DATE_SECTION_ADD,
  CUSTOM_ONE_TEXT_SECTION_ADD,
  CUSTOM_TWO_TEXT_SECTION_ADD,
  CUSTOM_THREE_TEXT_SECTION_ADD,
  CUSTOM_FOUR_TEXT_SECTION_ADD,
  CUSTOM_FIVE_TEXT_SECTION_ADD,
  CUSTOM_SIX_TEXT_SECTION_ADD,
  CUSTOM_SEVEN_TEXT_SECTION_ADD,
  CUSTOM_EIGHT_TEXT_SECTION_ADD,
  CUSTOM_NINE_TEXT_SECTION_ADD,
  CUSTOM_TEN_TEXT_SECTION_ADD,
  CUSTOM_ELEVEN_TEXT_SECTION_ADD,
  CUSTOM_TWELVE_TEXT_SECTION_ADD,
  CUSTOM_THIRTEEN_TEXT_SECTION_ADD,
  CUSTOM_FOURTEEN_TEXT_SECTION_ADD,
  CUSTOM_FIFTEEN_TEXT_SECTION_ADD,
  CUSTOM_SIXTEEN_TEXT_SECTION_ADD,
  CUSTOM_SEVENTEEN_TEXT_SECTION_ADD,
  CUSTOM_EIGHTEEN_TEXT_SECTION_ADD,
  CUSTOM_NINETEEN_TEXT_SECTION_ADD,
  CUSTOM_TWENTY_TEXT_SECTION_ADD,
  SAMPLE_CUSTOM_DATE_SECTION_ADD,
  SAMPLE_CUSTOM_DATE_SECTION_UPDATE,
  CUSTOM_TEXT_SECTION_ADD,
  CUSTOM_TEXT_SECTION_UPTEXT,
  SAMPLE_CUSTOM_TEXT_SECTION_ADD,
  SAMPLE_CUSTOM_TEXT_SECTION_UPTEXT,
} from "../Constants/EditorConstants";
import { customDate, customText } from "../../masterData";

export const customTwoTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_ONE_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};

export const customOneTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_ONE_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customThreeTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_THREE_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customFourTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_FOUR_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customFiveTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_FIVE_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customSixTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_SIX_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customSevenTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_SEVEN_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customEightTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_EIGHT_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customNineTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_NINE_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customTenTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_TEN_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customElevenTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_ELEVEN_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customTwelveTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_TWELVE_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customThirteenTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_THIRTEEN_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customFourteenTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_FOURTEEN_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customFifteenTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_FIFTEEN_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customSixteenTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_SIXTEEN_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customSeventeenTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_SEVENTEEN_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customEighteenTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_EIGHTEEN_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customNineteenTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_NINETEEN_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customTwentyTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case CUSTOM_TWENTY_TEXT_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customOneDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_ONE_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customTwoDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_TWO_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customThreeDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_THREE_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customFourDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_FOUR_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customFiveDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_FIVE_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customSixDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_SIX_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customSevenDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_SEVEN_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customEightDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_EIGHT_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customNineDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_NINE_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customTenDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_TEN_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customElevenDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_ELEVEN_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customTwelveDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_TWELVE_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customThirteenDateSectionReducers = (
  state = customDate,
  action
) => {
  switch (action.type) {
    case CUSTOM_THIRTEEN_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customFourteenDateSectionReducers = (
  state = customDate,
  action
) => {
  switch (action.type) {
    case CUSTOM_FOURTEEN_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customFifteenDateSectionReducers = (
  state = customDate,
  action
) => {
  switch (action.type) {
    case CUSTOM_FIFTEEN_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customSixteenDateSectionReducers = (
  state = customDate,
  action
) => {
  switch (action.type) {
    case CUSTOM_SIXTEEN_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customSeventeenDateSectionReducers = (
  state = customDate,
  action
) => {
  switch (action.type) {
    case CUSTOM_SEVENTEEN_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customEighteenDateSectionReducers = (
  state = customDate,
  action
) => {
  switch (action.type) {
    case CUSTOM_EIGHTEEN_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customNineteenDateSectionReducers = (
  state = customDate,
  action
) => {
  switch (action.type) {
    case CUSTOM_NINETEEN_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};
export const customTwentyDateSectionReducers = (state = customDate, action) => {
  switch (action.type) {
    case CUSTOM_TWENTY_DATE_SECTION_ADD:
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};

export const sampleCustomDateSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case SAMPLE_CUSTOM_DATE_SECTION_ADD:
      return customText;
    default:
      return state;
  }
};

export const sampleCustomTextSectionReducers = (state = customText, action) => {
  switch (action.type) {
    case SAMPLE_CUSTOM_TEXT_SECTION_ADD:
      return customText;
    case SAMPLE_CUSTOM_TEXT_SECTION_UPTEXT:
      return action.payload;
    default:
      return state;
  }
};
