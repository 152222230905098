import { useState, createContext, useEffect } from "react";

export const jsonEditorContext = createContext(null);

export const JsonEditorContextProvider = (props) => {
  const [json, setJson] = useState("");
  const [isJson, setIsJson] = useState(false);
  const [isSample, setIsSample] = useState(false);
  const [jsonIndex, setJsonIndex] = useState("");

  const [coverJson, setCoverJson] = useState("");
  const [isCoverJson, setIsCoverJson] = useState(false);
  const [isCoverSample, setIsCoverSample] = useState(false);
  const [jsonCoverIndex, setJsonCoverIndex] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [improveTexts, setImproveTexts] = useState("");
  const [suggestionModalPostion, setSuggestionModalPostion] = useState({
    top: 0,
    right: 0,
  });

  return (
    <jsonEditorContext.Provider
      value={{
        json,
        setJson,
        isJson,
        setIsJson,
        jsonIndex,
        setJsonIndex,
        isSample,
        setIsSample,
        coverJson,
        setCoverJson,
        isCoverJson,
        setIsCoverJson,
        isCoverSample,
        setIsCoverSample,
        jsonCoverIndex,
        setJsonCoverIndex,
        showSuggestion,
        setShowSuggestion,
        suggestionModalPostion,
        setSuggestionModalPostion,
        improveTexts,
        setImproveTexts,
      }}
    >
      {props.children}
    </jsonEditorContext.Provider>
  );
};
