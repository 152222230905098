import axios from "axios";
import { resumeApi } from "../../Utils/Api";
import { COLORCHANGE_SUCCESS } from "../Constants/CustomizationConstants";
import {
  ACHIEVEMENTS_ADD,
  EDUCATION_ADD,
  INTEREST_ADD,
  LANGUAGES_ADD,
  POSITION_ADD,
  PROFILE_ADD,
  SKILLS_ADD,
  SUMMARY_ADD,
  WORK_EXPERIENCE_ADD,
} from "../Constants/EditorConstants";
import {
  LOADING_REQUEST,
  LOADING_SUCCESS,
} from "../Constants/LoadingConstants";
import {
  TEMPLATE_GET_SUCCESS,
  TEMPLATE_GET_FAILED,
  GET_TEMPLATE_ID,
  CHANGE_TEMPLATE_SUCCESS,
  TEMPLATE_GET_PARTICULAR_SUCCESS,
  TEMPLATE_GET_PARTICULAR_FAILED,
  TEMPLATE_COUNT_SUCCESS,
  TEMPLATE_COUNT_FAILED,
  COVER_TEMPLATE_GET_PARTICULAR_SUCCESS,
  COVER_TEMPLATE_GET_PARTICULAR_FAILED,
  COVER_TEMPLATE_GET_SUCCESS,
  COVER_TEMPLATE_GET_FAILED,
} from "../Constants/TemplateConstants";

const baseurl = process.env.REACT_APP_API_URL;

export const templateCountAdd = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${baseurl}/api/template/count/${id}`,
      {},
      config
    );

    dispatch({
      type: TEMPLATE_COUNT_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: TEMPLATE_COUNT_FAILED,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const coverTemplateGetParticularActions =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });

      const {
        userData: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseurl}/api/coverTemplate/${id}`,
        config
      );

      dispatch({
        type: COVER_TEMPLATE_GET_PARTICULAR_SUCCESS,
        payload: data,
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: COVER_TEMPLATE_GET_PARTICULAR_FAILED,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const templateGetParticularActions =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });

      const {
        userData: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(`${baseurl}/api/template/${id}`, config);

      dispatch({
        type: TEMPLATE_GET_PARTICULAR_SUCCESS,
        payload: data,
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: TEMPLATE_GET_PARTICULAR_FAILED,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const covertemplateGetActions =
  (premium, page, title, type, searchData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });

      const { userData, jwtGenerate } = getState();

      let token =
        userData && userData.userInfo && userData.userInfo.token
          ? userData && userData.userInfo && userData.userInfo.token
          : jwtGenerate &&
            jwtGenerate.data &&
            jwtGenerate &&
            jwtGenerate.data.token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let url = `${baseurl}/api/covertemplate?type=${premium}`;

      let apiUrl = "";
      if (title && title != "search" && searchData == undefined) {
        apiUrl = `${url}&sort=${title}&direction=${type}&page=${page}`;
      } else if (
        title &&
        title != "search" &&
        searchData &&
        searchData == "premium"
      ) {
        apiUrl = `${url}&search=${searchData}&sort=${title}&direction=${type}&page=${page}`;
      } else if (
        title &&
        title != "search" &&
        searchData &&
        searchData.length > 0
      ) {
        apiUrl = `${url}&search=${searchData}&sort=${title}&direction=${type}&page=${page}`;
      } else if (title && title == "search") {
        apiUrl = `${url}&search=${type}&page=${page}`;
      } else {
        apiUrl = `${url}&page=${page}`;
      }
      const { data } = await axios.get(`${apiUrl}`, config);
      if (data && data.invalid) {
        localStorage.removeItem("guestToken");
        localStorage.removeItem("userInfo");
        window.location.reload();
      }
      dispatch({
        type: COVER_TEMPLATE_GET_SUCCESS,
        payload: data,
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: COVER_TEMPLATE_GET_FAILED,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const templateGetActions =
  (premium, page, title, type, searchData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });

      const { userData, jwtGenerate } = getState();

      let token =
        userData && userData.userInfo && userData.userInfo.token
          ? userData && userData.userInfo && userData.userInfo.token
          : jwtGenerate &&
            jwtGenerate.data &&
            jwtGenerate &&
            jwtGenerate.data.token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let url = `${baseurl}/api/template?type=${premium}`;
      let apiUrl = "";
      if (title && title != "search" && searchData == undefined) {
        apiUrl = `${url}&sort=${title}&direction=${type}&page=${page}`;
      } else if (
        title &&
        title != "search" &&
        searchData &&
        searchData == "premium"
      ) {
        apiUrl = `${url}&search=${searchData}&sort=${title}&direction=${type}&page=${page}`;
      } else if (
        title &&
        title != "search" &&
        searchData &&
        searchData.length > 0
      ) {
        apiUrl = `${url}&search=${searchData}&sort=${title}&direction=${type}&page=${page}`;
      } else if (title && title == "search") {
        apiUrl = `${url}&search=${type}&page=${page}`;
      } else {
        apiUrl = `${url}&page=${page}`;
      }
      const data = await axios.get(`${apiUrl}`, config);
      if (data && data.data && data.data.invalid) {
        localStorage.removeItem("guestToken");
        localStorage.removeItem("userInfo");
        // window.location.reload();
      }

      dispatch({
        type: TEMPLATE_GET_SUCCESS,
        payload: data,
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: TEMPLATE_GET_FAILED,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const getTemplateId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });
    dispatch({
      type: GET_TEMPLATE_ID,
      payload: id,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    localStorage.setItem("templateId", id);
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  }
};

export const changeTemplateActions =
  (id, loadData = false) =>
  async (dispatch, getState) => {
    const { templateList } = getState();
    const { editorData } = getState();
    let filterTemplate = templateList.list.data.filter((res) => res.id === id);

    filterTemplate = loadData
      ? filterTemplate[0].sampledata
      : filterTemplate[0].content;

    let parseData = JSON.parse(filterTemplate);
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });
    dispatch({
      type: POSITION_ADD,
      payload: parseData.sectionPosition,
    });
    dispatch({
      type: COLORCHANGE_SUCCESS,
      payload: parseData.customization,
    });

    if (loadData) {
      dispatch({
        type: PROFILE_ADD,
        payload: parseData.profileData,
      });
      dispatch({
        type: SKILLS_ADD,
        payload: parseData.skillsData,
      });
      dispatch({
        type: EDUCATION_ADD,
        payload: parseData.educationData,
      });
      dispatch({
        type: SUMMARY_ADD,
        payload: parseData.summaryData,
      });
      dispatch({
        type: WORK_EXPERIENCE_ADD,
        payload: parseData.experienceData,
      });
      dispatch({
        type: ACHIEVEMENTS_ADD,
        payload: parseData.achievementsData,
      });
      dispatch({
        type: LANGUAGES_ADD,
        payload: parseData.languagesData,
      });
      dispatch({
        type: INTEREST_ADD,
        payload: parseData.interestsData,
      });

      dispatch({
        type: COLORCHANGE_SUCCESS,
        payload: parseData.customization,
      });
      dispatch({
        type: LOADING_SUCCESS,
        payload: false,
      });
    }

    try {
      dispatch({
        type: LOADING_REQUEST,
        payload: true,
      });
      dispatch({
        type: CHANGE_TEMPLATE_SUCCESS,
        payload: "id",
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    }
  };
