export const COVER_CREATE_REQUEST = "COVER_CREATE_REQUEST";
export const COVER_CREATE_SUCCESS = "COVER_CREATE_SUCCESS";
export const COVER_CREATE_FAIL = "COVER_CREATE_FAIL";
export const COVER_CREATE_RESET = "COVER_CREATE_RESET";

export const GET_COVER_REQUEST = "GET_COVER_REQUEST";
export const GET_COVER_SUCCESS = "GET_COVER_SUCCESS";
export const GET_COVER_FAIL = "GET_COVER_FAIL";

export const GET_COVERBYID_SUCCESS = "GET_COVERBYID_SUCCESS";
export const GET_COVERBYID_FAIL = "GET_COVERBYID_FAIL";

export const COVER_UPDATE_REQUEST = "COVER_UPDATE_REQUEST";
export const COVER_UPDATE_SUCCESS = "COVER_UPDATE_SUCCESS";
export const COVER_UPDATE_FAIL = "COVER_UPDATE_FAIL";
export const COVER_UPDATE_RESET = "COVER_UPDATE_RESET";

export const COVER_DELETE_REQUEST = "COVER_DELETE_REQUEST";
export const COVER_DELETE_SUCCESS = "COVER_DELETE_SUCCESS";
export const COVER_DELETE_FAIL = "COVER_DELETE_FAIL";
export const COVER_DELETE_RESET = "COVER_DELETE_RESET";

export const COVER_DOWNLOAD_REQUEST = "COVER_DOWNLOAD_REQUEST";
export const COVER_DOWNLOAD_SUCCESS = "COVER_DOWNLOAD_SUCCESS";
export const COVER_DOWNLOAD_FAIL = "COVER_DOWNLOAD_FAIL";
export const COVER_DOWNLOAD_RESET = "COVER_DOWNLOAD_RESET";

export const COVER_LAST_UPDATED_AT = "COVER_LAST_UPDATED_AT";
