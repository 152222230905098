import {
  FONTCHANGE_REQUEST,
  FONTCHANGE_SUCCESS,
  FONTCHANGE_FAILED,
  COLORCHANGE_REQUEST,
  COLORCHANGE_SUCCESS,
  COLORCHANGE_FAILED,
  LINEHEIGHTCHANGE_REQUEST,
  LINEHEIGHTCHANGE_SUCCESS,
  LINEHEIGHTCHANGE_FAILED,
  FONTSIZECHANGE_REQUEST,
  FONTSIZECHANGE_SUCCESS,
  FONTSIZECHANGE_FAILED,
  SAMPLE_FONTCHANGE_REQUEST,
  SAMPLE_FONTCHANGE_SUCCESS,
  SAMPLE_FONTCHANGE_FAILED,
  SAMPLE_COLORCHANGE_REQUEST,
  SAMPLE_COLORCHANGE_SUCCESS,
  SAMPLE_COLORCHANGE_FAILED,
  SAMPLE_LINEHEIGHTCHANGE_REQUEST,
  SAMPLE_LINEHEIGHTCHANGE_SUCCESS,
  SAMPLE_LINEHEIGHTCHANGE_FAILED,
  SAMPLE_FONTSIZECHANGE_REQUEST,
  SAMPLE_FONTSIZECHANGE_SUCCESS,
  SAMPLE_FONTSIZECHANGE_FAILED,
  COVER_COLORCHANGE_REQUEST,
  COVER_COLORCHANGE_SUCCESS,
  COVER_COLORCHANGE_FAILED,
} from "../Constants/CustomizationConstants";

const initialState = {
  resumeLayout: "double",
  isProfileLayoutDifferent: false,
  lsbar_width: "30%",
  rsbar_width: "70%",
  colors: {
    primaryColor: "#27496d",
    secondaryColor: "#00909e",
    descriptionColor: "#656665",
    linkColor: "#616265",
    bgColor: "#ffffff",
  },
  skillColor: {
    c_hundred: "#04AA6D",
    c_eighty: "#2196F3",
    c_sixty: "#808080",
    c_forty: "#f44336",
    c_twenty: "#f44336",
  },
  background: {
    bgShow: true,
    bgImage: "bg14.png",
  },
  fonts: {
    titleFont: "Roboto",
    bodyFont: "Roboto",
    titleFontSize: "20px",
    bodyFontSize: "14px",
    bold: "700",
    medium: "500",
    regular: "400",
    multi_color_option: [
      {
        primary_color: "#27496d",
        secondary_color: "#00909e",
      },
      {
        primary_color: "#27496d",
        secondary_color: "#00909e",
      },
      {
        primary_color: "#27496d",
        secondary_color: "#00909e",
      },
      {
        primary_color: "#27496d",
        secondary_color: "#00909e",
      },
    ],
  },
  lineHeight: "1.5",
  SectionMargin: "12px",
  PageMargin: "30px",
};

export const coverCustomizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case COVER_COLORCHANGE_REQUEST:
      return { loading: true };
    case COVER_COLORCHANGE_SUCCESS:
      return action.payload;
    case COVER_COLORCHANGE_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sampleCustomizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAMPLE_FONTCHANGE_REQUEST:
      return { loading: true };
    case SAMPLE_FONTCHANGE_SUCCESS:
      return action.payload;
    case SAMPLE_FONTCHANGE_FAILED:
      return { ...state, loading: false, error: action.payload };
    case SAMPLE_COLORCHANGE_REQUEST:
      return { loading: true };
    case SAMPLE_COLORCHANGE_SUCCESS:
      return action.payload;
    case SAMPLE_COLORCHANGE_FAILED:
      return { ...state, loading: false, error: action.payload };
    case SAMPLE_FONTSIZECHANGE_REQUEST:
      return { loading: true };
    case SAMPLE_FONTSIZECHANGE_SUCCESS:
      return action.payload;
    case SAMPLE_FONTSIZECHANGE_FAILED:
      return { ...state, loading: false, error: action.payload };
    case SAMPLE_LINEHEIGHTCHANGE_REQUEST:
      return { loading: true };
    case SAMPLE_LINEHEIGHTCHANGE_SUCCESS:
      return action.payload;
    case SAMPLE_LINEHEIGHTCHANGE_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FONTCHANGE_REQUEST:
      return { loading: true };
    case FONTCHANGE_SUCCESS:
      return action.payload;
    case FONTCHANGE_FAILED:
      return { ...state, loading: false, error: action.payload };
    case COLORCHANGE_REQUEST:
      return { loading: true };
    case COLORCHANGE_SUCCESS:
      return action.payload;
    case COLORCHANGE_FAILED:
      return { ...state, loading: false, error: action.payload };
    case FONTSIZECHANGE_REQUEST:
      return { loading: true };
    case FONTSIZECHANGE_SUCCESS:
      return action.payload;
    case FONTSIZECHANGE_FAILED:
      return { ...state, loading: false, error: action.payload };
    case LINEHEIGHTCHANGE_REQUEST:
      return { loading: true };
    case LINEHEIGHTCHANGE_SUCCESS:
      return action.payload;
    case LINEHEIGHTCHANGE_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
