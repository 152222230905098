import { useState, createContext, useEffect } from "react";
import { resumeEditActions } from "../Store/Actions/ResumeActions";
import { useDispatch, useSelector } from "react-redux";

export const ResumePopupContext = createContext(null);

export const ResumePopupContextProvider = (props) => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [preview, setPreview] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteResume, setDeleteResume] = useState(false);
  const [renameResume, setRenameResume] = useState(false);
  const [guestPopup, setGuestPopup] = useState(false);
  const [sectionId, setSectionId] = useState("");
  const [arrayIndex, setArrayIndex] = useState("");
  const [customIndex, setCustomIndex] = useState("");
  const [sampleModal, setSampleModal] = useState(false);
  const [sideBasic, setSideBasic] = useState(false);
  const [jobTitle, setJobTile] = useState("");
  const [suggestionSection, setSuggestionSection] = useState("");
  const [suggestionIndex, setSuggestionIndex] = useState("");

  // For Checking Id
  const id = "work";
  return (
    <ResumePopupContext.Provider
      value={{
        showPopup,
        setShowPopup,
        sectionId,
        setSectionId,
        arrayIndex,
        setArrayIndex,
        showDeletePopup,
        setShowDeletePopup,
        guestPopup,
        setGuestPopup,
        deleteResume,
        setDeleteResume,
        renameResume,
        setRenameResume,
        preview,
        sampleModal,
        setSampleModal,
        setPreview,
        customIndex,
        setCustomIndex,
        sideBasic,
        setSideBasic,
        jobTitle,
        setJobTile,
        suggestionSection,
        setSuggestionSection,
        suggestionIndex,
        setSuggestionIndex,
      }}
    >
      {props.children}
    </ResumePopupContext.Provider>
  );
};
