import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { ActionCreators, applyUndo } from "react-redux-undo";
import undoable from "redux-undo";

import {
  loadingReducers,
  editorLoadingReducers,
  resumeLoadingReducers,
} from "./reducers/loadingReducers";

import {
  ProfileReducers,
  sampleProfileReducers,
  isJobPresentReducers,
} from "./reducers/ProfileReducers";
import {
  WorkexperienceReducers,
  sampleWorkexperienceReducers,
} from "./reducers/WorkexperienceReducers";
import {
  EducationReducers,
  sampleEducationReducers,
} from "./reducers/EducationReducers";
import {
  SummaryReducers,
  sampleSummaryReducers,
} from "./reducers/SummaryReducers";
import {
  SkillsReducers,
  sampleSkillsReducers,
} from "./reducers/SkillsReducers";
import {
  AchievementsReducers,
  sampleAchievementsReducers,
} from "./reducers/AchievementsReducers";
import {
  SectionPositionReducers,
  sampleSectionPositionReducers,
} from "./reducers/SectionPositonReducers";
import {
  layoutReducers,
  calculateHeightReducers,
  sampleLayoutReducers,
} from "./reducers/LayoutReducers";
import {
  customizationReducer,
  sampleCustomizationReducer,
  coverCustomizationReducer,
} from "./reducers/CustomizationReducers";
import {
  LanguagesReducers,
  sampleLanguagesReducers,
} from "./reducers/LanguagesReducers";
import {
  editorSettingsReducers,
  sampleEditorSettingsReducers,
} from "./reducers/editorSettingsReducers";
import {
  InterestsReducers,
  sampleInterestsReducers,
} from "./reducers/InterestsReducers";
import {
  fromReducers,
  isCoverJobPresentReducers,
} from "./reducers/FromReducers";
import { toReducers } from "./reducers/ToReducers";
import { contentReducers } from "./reducers/ContentReducers";

import {
  planAddReducer,
  planListReducer,
  planParticularReducer,
  planEditReducer,
  planDeleteReducer,
  planShowReducer,
  planSubmitReducer,
  planValidateReducer,
  userSubscriptionReducer,
  cancelSubscriptionReducer,
  getClientSecretReducer,
  updateUserPlanReducer,
  stripeDefaultCheckoutReducer,
  userPlanVisitReducer,
} from "./reducers/PlanReducers";

import { jobListReducer, categoryBasedReducer } from "./reducers/JobReducers";

import {
  userRegisterReducer,
  userLoginReducer,
  userLogOutReducer,
  userSocialReducer,
  callbackReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  resendMailReducer,
  jwtGenerateReducer,
  checkMailReducer,
} from "./reducers/authReducers";
import {
  UserParticularReducers,
  changePasswordReducers,
  UserUpdateReducers,
  UserDeleteReducers,
  UserReducers,
  ssoDisconnectReducers,
  ssoUpdateReducers,
  feedbackReducers,
  feedbacklistReducers,
  userVerifyReducers,
  userUnsubscripeReducers,
} from "./reducers/UserReducers";

import {
  suggestionListReducer,
  suggestionParticularReducer,
  suggestionAddReducer,
  suggestionEditReducer,
  suggestionDeleteReducer,
  suggestionFrontReducer,
} from "./reducers/SuggestionReducers";

import {
  sampleListReducer,
  sampleAddReducer,
  sampleParticularReducer,
  sampleEditReducer,
  sampleDeleteReducer,
  samplePreviewReducer,
  getExperienceReducer,
  coverSampleListReducer,
} from "./reducers/SampleReducers";

import {
  createResumeReducer,
  resumeupdateReducer,
  resumeGetReducer,
  resumeGetByIdReducer,
  duplicateResumeReducer,
  deleteResumeReducer,
  resumeDownloadReducer,
  duplicateTitleReducer,
  resumeNameChangeReducer,
  resumeLastUpdatedAtReducer,
  resumePreviewReducer,
  adminListReducer,
  resumePreviewImageReducer,
  resumeDataUpdateReducer,
  editorDataReducers,
  tempResumeReducers,
  tempResumeGetReducers,
  resumeGetHistoryReducers,
  resumeUpdateToRedisReducer
} from "./reducers/resumeReducers";
import {
  TemplateParticularReducers,
  TemplateReducers,
  TemplateCountAddReducers,
  CoverTemplateReducers,
  CoverTemplateParticularReducers,
} from "./reducers/TemplateReducers";
import { HeaderReducers } from "./reducers/HeaderReducers";
import {
  coverCreateReducer,
  coverListReducer,
  coverParticularReducer,
  coverUpdateReducer,
  coverDeleteReducer,
  coverDownloadReducer,
  coverLastUpdatedAtReducer,
  coverDataReducers,
} from "./reducers/coverReducers";

import {
  undoRedoReducers,
  undoRedoCoverReducers,
} from "./reducers/undoRedoReducers";

import {
  sampleCustomDateSectionReducers,
  sampleCustomTextSectionReducers,
} from "./reducers/customSectionReducers";

import { analyzeResumeReducer } from "./reducers/AnalyzeReducers";
const middleware = [thunk];

const reducer = combineReducers({
  editorData: editorDataReducers,
  layout: layoutReducers,
  calculateHeight: calculateHeightReducers,
  coverData: coverDataReducers,
  //   coverData: combineReducers({
  //     from: fromReducers,
  //     to: toReducers,
  //     content: contentReducers,
  //     customization: coverCustomizationReducer,
  //     editorSettings: coverSettingsReducer,
  //   }),
  sampleData: combineReducers({
    profile: sampleProfileReducers,
    experience: sampleWorkexperienceReducers,
    education: sampleEducationReducers,
    summary: sampleSummaryReducers,
    skills: sampleSkillsReducers,
    achievements: sampleAchievementsReducers,
    languages: sampleLanguagesReducers,
    interests: sampleInterestsReducers,
    customDate: sampleCustomDateSectionReducers,
    customText: sampleCustomTextSectionReducers,
    layout: sampleLayoutReducers,
    sectionPosition: sampleSectionPositionReducers,
    customization: sampleCustomizationReducer,
    from: fromReducers,
    to: toReducers,
    content: contentReducers,
  }),
  createResume: createResumeReducer,
  isJobPresent: isJobPresentReducers,
  resumeHistory: resumeGetHistoryReducers,
  jwtGenerate: jwtGenerateReducer,
  isCoverJobPresent: isCoverJobPresentReducers,
  loading: loadingReducers,
  coverCreate: coverCreateReducer,
  coverList: coverListReducer,
  coverParticular: coverParticularReducer,
  coverUpdate: coverUpdateReducer,
  coverDelete: coverDeleteReducer,
  coverDownload: coverDownloadReducer,
  CoverTemplates: CoverTemplateReducers,
  coverSampleList: coverSampleListReducer,
  CoverTemplateParticular: CoverTemplateParticularReducers,
  coverLastUpdatedAt: coverLastUpdatedAtReducer,
  resumeLoading: resumeLoadingReducers,
  editorLoading: editorLoadingReducers,
  userRegister: userRegisterReducer,
  userData: userLoginReducer,
  resendMail: resendMailReducer,
  userLogOut: userLogOutReducer,
  particularuser: UserParticularReducers,
  feedback: feedbackReducers,
  feedbacklist: feedbacklistReducers,
  userSocial: userSocialReducer,
  userVerify: userVerifyReducers,
  categoryBased: categoryBasedReducer,
  forgotpassword: forgotPasswordReducer,
  resetpassword: resetPasswordReducer,
  changePassword: changePasswordReducers,
  userUpdate: UserUpdateReducers,
  checkMail: checkMailReducer,
  userDelete: UserDeleteReducers,
  ssoUpdate: ssoUpdateReducers,
  allUser: UserReducers,
  ssoDisconnect: ssoDisconnectReducers,
  resumeList: resumeGetReducer,
  TemplateParticular: TemplateParticularReducers,
  TemplateCount: TemplateCountAddReducers,
  templateList: TemplateReducers,
  resumeGetById: resumeGetByIdReducer,
  tempResume: tempResumeReducers,
  tempResumeGet: tempResumeGetReducers,
  resumeUpdate: resumeupdateReducer,
  resumeUpdateToRedis:resumeUpdateToRedisReducer,
  resumePreview: resumePreviewReducer,
  resumeDownload: resumeDownloadReducer,
  deleteResume: deleteResumeReducer,
  duplicateResume: duplicateResumeReducer,
  duplicateTitle: duplicateTitleReducer,
  header: HeaderReducers,
  resumeNameChange: resumeNameChangeReducer,
  resumeLastUpdatedAt: resumeLastUpdatedAtReducer,

  jobList: jobListReducer,
  adminList: adminListReducer,
  planAdd: planAddReducer,
  planList: planListReducer,
  planParticular: planParticularReducer,
  planEdit: planEditReducer,
  planDelete: planDeleteReducer,
  cancelSubscription: cancelSubscriptionReducer,
  suggestionList: suggestionListReducer,
  suggestionParticular: suggestionParticularReducer,
  suggestionAdd: suggestionAddReducer,
  suggestionEdit: suggestionEditReducer,
  suggestionDelete: suggestionDeleteReducer,
  suggestionFront: suggestionFrontReducer,
  sampleList: sampleListReducer,
  sampleAdd: sampleAddReducer,
  sampleParticular: sampleParticularReducer,
  sampleEdit: sampleEditReducer,
  sampleDelete: sampleDeleteReducer,
  samplePreview: samplePreviewReducer,
  planShow: planShowReducer,
  planSubmit: planSubmitReducer,
  planValidate: planValidateReducer,
  userPlanVisit: userPlanVisitReducer,
  userSubscription: userSubscriptionReducer,
  resumePreviewImage: resumePreviewImageReducer,
  getExperience: getExperienceReducer,
  resumeDataUpdate: resumeDataUpdateReducer,
  getClientSecret: getClientSecretReducer,
  stripeDefaultCheckout: stripeDefaultCheckoutReducer,
  updateUserPlan: updateUserPlanReducer,
  analyzeResume: analyzeResumeReducer,
  userUnsubscribe: userUnsubscripeReducers,
  undoRedo: undoRedoReducers,
  undoRedoCover: undoRedoCoverReducers,
});

const userDataFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const guestDataFromStorage = localStorage.getItem("guestToken")
  ? {
      token: localStorage.getItem("guestToken"),
      success: true,
    }
  : null;

const initialState = {
  userData: { userInfo: userDataFromStorage },
  jwtGenerate: { data: guestDataFromStorage },
};

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 200 });

const store = createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
