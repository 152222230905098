export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";
export const USER_LOGOUT_RESET = "USER_LOGOUT_RESET";

export const USER_SSO_REQUEST = "USER_SSO_REQUEST";
export const USER_SSO_SUCCESS = "USER_SSO_SUCCESS";
export const USER_SSO_FAIL = "USER_SSO_FAIL";
export const USER_SSO_RESET = "USER_SSO_RESET";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";

export const TOKEN_GENERATE_SUCCESS = "TOKEN_GENERATE_SUCCESS";
export const TOKEN_GENERATE_FAIL = "TOKEN_GENERATE_FAIL";
export const TOKEN_GENERATE_RESET = "TOKEN_GENERATE_RESET";

export const CHECKEMAIL_SUCCESS = "CHECKEMAIL_SUCCESS";
export const CHECKEMAIL_FAIL = "CHECKEMAIL_FAIL";
export const CHECKEMAIL_RESET = "CHECKEMAIL_RESET";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const MAIL_RESEND_SUCCESS = "MAIL_RESEND_SUCCESS";
export const MAIL_RESEND_FAIL = "MAIL_RESEND_FAIL";
export const MAIL_RESEND_RESET = "MAIL_RESEND_RESET";
