import React from "react";
import { withRouter } from "react-router-dom";
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
const AuthVerify = (props) => {
  props.history.listen(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    if (user) {
      const decodedJwt = parseJwt(user.token);
      if (decodedJwt && decodedJwt.exp * 1000 < Date.now()) {
        localStorage.removeItem("userInfo");
        document.cookie = `logged=false; path=/; secure; domain=qwikresume.com; expires=${new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24 * 365
        ).toGMTString()}`;
        document.location.href = "/";
      }
    }
  });
  return <div></div>;
};
export default withRouter(AuthVerify);
