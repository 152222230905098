import { useState, createContext, useEffect, useContext } from "react";
import { coverEditActions } from "../Store/Actions/CoverLetterActions";
import { useDispatch, useSelector } from "react-redux";
import { jsonEditorContext } from "./jsonEditorContext";

export const CoverUpdateContext = createContext(null);

export const CoverUpdateContextProvider = (props) => {
  const { isSample, setIsSample } = useContext(jsonEditorContext);

  const dispatch = useDispatch();
  const [coverUpdated, setCoverUpdated] = useState("");
  const coverLetter = useSelector((state) => state.coverParticular.coverLetter);
  const editorData = useSelector((state) => state.coverData);
  const [sectionFocusData, setSectionFocusData] = useState("");
  const [visualEditorFocus, setVisualEditorFocus] = useState(false);

  useEffect(() => {
    if (!isSample) {
      resumePageUpdate(editorData);
    }
  }, [coverUpdated]);

  const resumePageUpdate = (content) => {
    if (coverLetter) {
      setCoverUpdated(coverLetter);
      var body = { ...coverLetter, content: JSON.stringify(content) };
      dispatch(coverEditActions(coverLetter.id, body));
    }
  };

  const backgroundUpdate = (img) => {
    let data = { ...editorData };
    if (!data.customization.background.bgShow) {
      data.customization.background.bgShow =
        !data.customization.background.bgShow;
    }
    data.customization.background.bgImage = img;
    setCoverUpdated(coverLetter);
    var body = { ...coverLetter, content: JSON.stringify(editorData) };
    dispatch(coverEditActions(coverLetter.id, body, data.customization));
  };

  const fontHandler = (e, name, type = "basic") => {
    let data = { ...editorData };
    data.customization.fonts[name] = e.target.value;
    setCoverUpdated(coverLetter);
    var body = { ...coverLetter, content: JSON.stringify(editorData) };
    dispatch(coverEditActions(coverLetter.id, body, data.customization));
  };

  const sizeHandler = (title, size, body, small, type = "basic") => {
    let data = { ...editorData };
    data.customization.fonts[title] = size;
    data.customization.fonts[body] = small;
    var body = { ...coverLetter, content: JSON.stringify(editorData) };
    dispatch(coverEditActions(coverLetter.id, body, data.customization));
  };

  const colorHandler = (e, name, update) => {
    let data = { ...editorData };
    data.customization.colors[name] = e.target.value;
    var body = { ...coverLetter, content: JSON.stringify(editorData) };
  };
  const changeHeaderSettings = (switchvalue) => {
    if (switchvalue == false) {
      let data = { ...editorData };
      data.editorSettings.from["headerStyle"] = "header3";
      data.editorSettings.from["isdefaultHeader"] = switchvalue;
      var body = { ...coverLetter, content: JSON.stringify(editorData) };
      dispatch(
        coverEditActions(
          coverLetter.id,
          body,
          data.customization,
          data.editorSettings
        )
      );
    } else if (switchvalue == true) {
      let data = { ...editorData };
      let head = data.editorSettings.from["defaultHeader"];
      data.editorSettings.from["headerStyle"] = head;
      data.editorSettings.from["isdefaultHeader"] = switchvalue;
      var body = { ...coverLetter, content: JSON.stringify(editorData) };
      dispatch(
        coverEditActions(
          coverLetter.id,
          body,
          data.customization,
          data.editorSettings
        )
      );
    }
  };

  const multiColorHandler = (primary, secondary, sectiontitleBgColor) => {
    let data = { ...editorData };

    data.customization.colors.primaryColor = primary;
    data.customization.colors.secondaryColor = secondary;
    data.customization.colors.sectiontitleBgColor = sectiontitleBgColor;
    setCoverUpdated(Math.random());
  };

  return (
    <CoverUpdateContext.Provider
      value={{
        backgroundUpdate,
        coverUpdated,
        setCoverUpdated,
        fontHandler,
        sizeHandler,
        colorHandler,
        multiColorHandler,
        sectionFocusData,
        setSectionFocusData,
        visualEditorFocus,
        setVisualEditorFocus,
        changeHeaderSettings,
      }}
    >
      {props.children}
    </CoverUpdateContext.Provider>
  );
};
