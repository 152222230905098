import {
  USER_GET_SUCCESS,
  USER_GET_FAILED,
  USER_PARTICULAR_FAILED,
  USER_PARTICULAR_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILED,
  USER_UPDATE_RESET,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAILED,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED,
  USER_DELETE_RESET,
  USER_DISCONNECT_SUCCESS,
  USER_DISCONNECT_FAILED,
  USER_SSOUPDATE_SUCCESS,
  USER_SSOUPDATE_FAILED,
  USER_FEEDBACK_SUCCESS,
  USER_FEEDBACK_FAILED,
  ADMIN_FEEDBACK_SUCCESS,
  ADMIN_FEEDBACK_FAILED,
  USER_FEEDBACK_RESET,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAILED,
  USER_VERIFY_RESET,
  USER_UNSUBSCRIPE_SUCCESS,
  USER_UNSUBSCRIPE_FAILED,
} from "../Constants/UserConstants";

export const userUnsubscripeReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_UNSUBSCRIPE_SUCCESS:
      return action.payload;
    case USER_UNSUBSCRIPE_FAILED:
      return { error: action.payload };
    // case USER_VERIFY_RESET:
    //   return {};
    default:
      return state;
  }
};

export const userVerifyReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_VERIFY_SUCCESS:
      return action.payload;
    case USER_VERIFY_FAILED:
      return { error: action.payload };
    case USER_VERIFY_RESET:
      return {};
    default:
      return state;
  }
};

export const feedbacklistReducers = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_FEEDBACK_SUCCESS:
      return action.payload;
    case ADMIN_FEEDBACK_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export const feedbackReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_FEEDBACK_SUCCESS:
      return action.payload;
    case USER_FEEDBACK_FAILED:
      return { error: action.payload };
    case USER_FEEDBACK_RESET:
      return {};
    default:
      return state;
  }
};

export const ssoUpdateReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_SSOUPDATE_SUCCESS:
      return action.payload;
    case USER_SSOUPDATE_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export const ssoDisconnectReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_DISCONNECT_SUCCESS:
      return action.payload;
    case USER_DISCONNECT_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export const socialLoginReducers = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_LOGIN_SUCCESS:
      return action.payload;
    case SOCIAL_LOGIN_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export const changePasswordReducers = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_SUCCESS:
      return action.payload;
    case CHANGE_PASSWORD_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export const UserUpdateReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_SUCCESS:
      return { data: action.payload };
    case USER_UPDATE_FAILED:
      return { data: action.payload };
    case USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const UserParticularReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_PARTICULAR_FAILED:
      return action.payload;
    case USER_PARTICULAR_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export const UserReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_GET_SUCCESS:
      return action.payload;
    case USER_GET_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export const UserDeleteReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_SUCCESS:
      return { data: action.payload };
    case USER_DELETE_FAILED:
      return { data: action.payload };
    case USER_DELETE_RESET:
      return { data: {} };
    default:
      return state;
  }
};
