import { useState, createContext, useEffect } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { userParticularGet } from "../Store/Actions/UserActions";
import {
  RESUME_PREVIEW_RESET,
  RESUME_DUPLICATE_RESET,
  RESUME_CREATE_RESET,
} from "../Store/Constants/ResumeConstants";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import { COVER_CREATE_RESET } from "../Store/Constants/CoverLetterConstants";
export const userTypeContext = createContext(null);

export const UserTypeContextProvider = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const path = window.location.pathname;
  const [userType, setUserType] = useState("free");
  const [isIndianUser, setIsIndianUser] = useState(false);
  const [userCountryCode, setUserCountryCode] = useState("US");
  const [showProPopup, setShowProPopup] = useState(false);
  const [popupType, setPopupType] = useState("resume");
  const [showProPopupMessage, setShowProPopupMessage] = useState(false);
  const [resumeCount, setResumeCount] = useState("");
  const [coverCount, setCoverCount] = useState("");
  const [maxCount, setMaxCount] = useState(1);
  const particularData = useSelector((state) => state.particularuser);
  const userInfo = useSelector((state) => state.userData.userInfo);
  const resumeCreate = useSelector((state) => state.createResume);
  const coverCreate = useSelector((state) => state.coverCreate);
  const resumeDuplicate = useSelector((state) => state.duplicateResume);
  const resumePreview = useSelector((state) => state.resumePreview);
  //   useEffect(() => {
  //     dispatch(userParticularGet());
  //   }, [dispatch, userInfo]);

  useEffect(() => {
    if (particularData && particularData.user) {
      setUserType(
        particularData && particularData.user && particularData.user.type
      );
      setResumeCount(
        particularData &&
          particularData.user &&
          particularData.user.resume_count
      );
      setCoverCount(
        particularData && particularData.user && particularData.user.cover_count
      );
      setMaxCount(
        particularData && particularData.user && particularData.user.max_count
      );
    } else if (userInfo && userInfo.user) {
      setUserType(userInfo?.user?.type);
    }
  }, [particularData, userInfo]);

  // useEffect(() => {
  //   if (userInfo && userInfo.user) {
  //     setUserType(userInfo?.user?.type);
  //     // setResumeCount(particularData && particularData.resume_count);
  //   }
  // }, [userInfo]);

  useEffect(() => {
    // const url = "https://api.adviceslip.com/advice";

    const fetchData = async () => {
      try {
        const { data } = await axios.get("https://geolocation-db.com/json/");
        // const response = await fetch(`https://api.ip2location.com/v2/?key=7IC8CUUSAO&ip=${res.data.IPv4}&format=json&package=WS25&&addon=continent,country,region,city,geotargeting,country_groupings,time_zone_info&lang=zh-cn`);
        // const json = await res.json();
        if (data && data.country_code) {
          setUserCountryCode(data && data.country_code);
        }
        if (data && data.country_code == "IN") {
          setIsIndianUser(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (resumeCreate && resumeCreate.create && resumeCreate.create.message) {
      if (path && path.includes("resume")) {
        history.push("/home");
        setShowProPopup(true);
        setShowProPopupMessage(
          resumeCreate && resumeCreate.create && resumeCreate.create.message
        );
        setPopupType("resume");
        // dispatch({ type: RESUME_CREATE_RESET });
      } else {
        setShowProPopup(true);
        setShowProPopupMessage(
          resumeCreate && resumeCreate.create && resumeCreate.create.message
        );
        dispatch({ type: RESUME_CREATE_RESET });
        setPopupType("resume");
      }
    }
  }, [resumeCreate]);

  useEffect(() => {
    if (coverCreate && coverCreate.data && coverCreate.data.message) {
      if (path && path.includes("cover")) {
        history.push("/home");
        setShowProPopup(true);
        setShowProPopupMessage(
          coverCreate && coverCreate.data && coverCreate.data.message
        );
        dispatch({ type: COVER_CREATE_RESET });
        setPopupType("resume");
      } else {
        setShowProPopup(true);
        setShowProPopupMessage(
          coverCreate && coverCreate.data && coverCreate.data.message
        );
        dispatch({ type: COVER_CREATE_RESET });
        setPopupType("resume");
      }
    }
  }, [coverCreate]);

  useEffect(() => {
    if (
      resumeDuplicate &&
      resumeDuplicate.data &&
      resumeDuplicate.data.message
    ) {
      setShowProPopup(true);
      dispatch({ type: RESUME_DUPLICATE_RESET });
    }
  }, [resumeDuplicate]);

  useEffect(() => {
    if (resumePreview && resumePreview.data && resumePreview.data.message) {
      setShowProPopup(true);
      setShowProPopupMessage(
        resumePreview && resumePreview.data && resumePreview.data.message
      );
      dispatch({ type: RESUME_PREVIEW_RESET });
      setPopupType("download");
    }
  }, [resumePreview]);
  return (
    <userTypeContext.Provider
      value={{
        userType,
        setUserType,
        resumeCount,
        setResumeCount,
        coverCount,
        maxCount,
        popupType,
        setPopupType,
        showProPopup,
        setShowProPopup,
        showProPopupMessage,
        setShowProPopupMessage,
        isIndianUser,
        userCountryCode,
      }}
    >
      {props.children}
    </userTypeContext.Provider>
  );
};
