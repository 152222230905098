import axios from "axios";
import {
  PROFILE_ADD,
  PROFILE_UPDATE,
  EDITOR_DELETE,
  SETTINGS_ADD,
  SETTINGS_UPDATE,
  SAMPLE_SETTINGS_ADD,
  SAMPLE_SETTINGS_UPDATE,
  JOB_ISPRESENT_REQUEST,
  JOB_ISPRESENT_SUCCESS,
  JOB_ISPRESENT_FAIL,
} from "../Constants/EditorConstants";
const baseUrl = process.env.REACT_APP_API_URL;

export const settingsAdd = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SETTINGS_ADD,
      payload: { ...data },
    });
  } catch (error) {
    dispatch({
      type: EDITOR_DELETE,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const profilData = (data) => async (dispatch) => {
  try {
    if (data && data.data) {
      dispatch({
        type: PROFILE_ADD,
        payload: { ...data, initialLoad: true },
      });
    }
  } catch (error) {
    dispatch({
      type: EDITOR_DELETE,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const isJobPresent = (jobTitle) => async (dispatch) => {
  try {
    dispatch({
      type: JOB_ISPRESENT_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (typeof jobTitle == "undefined") {
      jobTitle = "";
    }

    const data = await axios.get(
      `${baseUrl}/api/job/isPresent/${jobTitle}`,
      config
    );

    dispatch({
      type: JOB_ISPRESENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_ISPRESENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const profilUpdate = (data) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_UPDATE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EDITOR_DELETE,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};
