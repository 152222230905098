export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAILED = "USER_GET_FAILED";

export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_FAILED = "USER_EDIT_FAILED";

export const USER_FEEDBACK_SUCCESS = "USER_FEEDBACK_SUCCESS";
export const USER_FEEDBACK_FAILED = "USER_FEEDBACK_FAILED";
export const USER_FEEDBACK_RESET = "USER_FEEDBACK_RESET";

export const USER_VERIFY_SUCCESS = "USER_VERIFY_SUCCESS";
export const USER_VERIFY_FAILED = "USER_VERIFY_FAILED";
export const USER_VERIFY_RESET = "USER_VERIFY_RESET";

export const ADMIN_FEEDBACK_SUCCESS = "ADMIN_FEEDBACK_SUCCESS";
export const ADMIN_FEEDBACK_FAILED = "ADMIN_FEEDBACK_FAILED";

export const USER_PARTICULAR_FAILED = "USER_PARTICULAR_FAILED";
export const USER_PARTICULAR_SUCCESS = "USER_PARTICULAR_SUCCESS";

export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAILED = "SOCIAL_LOGIN_FAILED";

export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILED = "USER_DELETE_FAILED";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

export const USER_DISCONNECT_SUCCESS = "USER_DISCONNECT_SUCCESS";
export const USER_DISCONNECT_FAILED = "USER_DISCONNECT_FAILED";

export const USER_SSOUPDATE_SUCCESS = "USER_SSOUPDATE_SUCCESS";
export const USER_SSOUPDATE_FAILED = "USER_SSOUPDATE_FAILED";

export const USER_UNSUBSCRIPE_SUCCESS = "USER_UNSUBSCRIPE_SUCCESS";
export const USER_UNSUBSCRIPE_FAILED = "USER_UNSUBSCRIPE_FAILED";
