import React, { useState, useEffect } from "react";
import "./Feedback.scss";
import { useDispatch, useSelector } from "react-redux";
import { feedback } from "../../Store/Actions/UserActions";
import { USER_FEEDBACK_RESET } from "../../Store/Constants/UserConstants";
import OutsideClickHandler from "react-outside-click-handler";

function Feedback(props) {
  const dispatch = useDispatch();
  const feedbackData = useSelector((state) => state.feedback);
  const userData = useSelector((state) => state.userData.userInfo);

  const [formActive, setFormActive] = useState(false);
  const [errors, setErrors] = useState("");
  const [hoverSend, setHoverSend] = useState(false);
  const [hoverClose, setHoverClose] = useState(false);
  const [activeType, setActiveType] = useState("general");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitText, setSubmitText] = useState("Submit");
  const spinnerStyle = {
    maxWidth: "15px",
    position: "relative",
  };
  const [feedData, setFeedData] = useState({
    email: "",
    type: "general",
    feedback: "",
  });
  const handleOpenForm = () => {
    if (!formActive) {
      setSubmitText("Submit");
      setFormActive(false);
      setActiveType("general");
      setFeedData({
        email: "",
        type: "general",
        feedback: "",
      });
    }
    if (props && props.showFeedback) {
      props && props.setShowFeedback(false);
    }
    setFormActive(!formActive);
  };
  const handleMouseOver = () => {
    setHoverSend(true);
  };
  const handleMouseOut = () => {
    setHoverSend(false);
  };

  const handleCloseMouseOver = () => {
    setHoverClose(true);
  };
  const handleCloseMouseOut = () => {
    setHoverClose(false);
  };

  const handleType = (value) => {
    setFeedData((feedData) => ({ ...feedData, ["type"]: value }));
    setActiveType(value);
  };
  const handleEmail = (e) => {
    setFeedData((feedData) => ({ ...feedData, ["email"]: e.target.value }));
  };
  const handleTextArea = (e) => {
    setFeedData((feedData) => ({ ...feedData, ["feedback"]: e.target.value }));
  };

  const handleSubmit = () => {
    if (!submitLoading && !submitText.includes("Thanks")) {
      setSubmitLoading(true);
      dispatch(feedback(feedData));
    }
  };

  useEffect(() => {
    if (userData && userData.user) {
      setFeedData((feedData) => ({
        ...feedData,
        ["email"]: userData && userData.user && userData.user.email,
      }));
    }
  }, [userData]);

  useEffect(() => {
    if (feedbackData && feedbackData.success) {
      setSubmitText("Thanks!");
      dispatch({ type: USER_FEEDBACK_RESET });
      setSubmitLoading(false);

      setTimeout(() => {
        setSubmitText("Submit");
        setFormActive(false);
        props.setShowFeedback(false);
        setHoverSend(false);
        setActiveType("general");
        setFeedData({
          email: "",
          type: "general",
          feedback: "",
        });
      }, 2000);
    } else if (feedbackData && feedbackData.error) {
      setSubmitText("Error!");
      setErrors(feedbackData && feedbackData.error);
      setTimeout(() => {
        setSubmitLoading(false);
        props.setShowFeedback(false);
        dispatch({ type: USER_FEEDBACK_RESET });
        setSubmitText("Submit");
        setFormActive(false);
        setHoverSend(false);
        setActiveType("general");
        setErrors("");
        setFeedData({
          email: "",
          type: "general",
          feedback: "",
        });
      }, 3000);
    }
  }, [feedbackData]);

  useEffect(() => {
    if (props && props.showFeedback) {
      if (!formActive) {
        setSubmitText("Submit");
        setFormActive(false);
        setActiveType("general");
        setFeedData({
          email: "",
          type: "general",
          feedback: "",
        });
      }
      setFormActive(!formActive);
    }
  }, [props && props.showFeedback]);

  return (
    <>
      {formActive && (
        <div className="feedback-wrapper">
          {!formActive ? (
            <>
              <div
                className="feedback-button-div feedback-send-div"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <button onClick={handleOpenForm}>
                  {hoverSend ? (
                    "Send Feedback"
                  ) : (
                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                  )}
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                className="feedback-button-div feedback-send-div"
                onMouseOver={handleCloseMouseOver}
                onMouseOut={handleCloseMouseOut}
              >
                <button onClick={handleOpenForm}>
                  {hoverClose ? (
                    "Close Feedback"
                  ) : (
                    <i class="fa fa-times" aria-hidden="true"></i>
                  )}
                </button>
              </div>
            </>
          )}
          <OutsideClickHandler
            onOutsideClick={(e, value) => {
              const parentWithClass = e.target.closest(".feedback-modal");
              const anotherParentWithClass =
                e.target.closest(".feedback-wrapper");
              if (
                formActive &&
                parentWithClass == null &&
                anotherParentWithClass == null
              ) {
                setFormActive(false);
                setHoverSend(false);
              } else if (formActive && anotherParentWithClass != "") {
                setFormActive(true);
              }
            }}
          >
            {formActive && formActive && (
              <div className="feedback-modal">
                <div>
                  {errors && errors.email && (
                    <p className="error">
                      {errors &&
                        errors.email &&
                        errors.email[0] &&
                        errors.email[0]}
                    </p>
                  )}
                  <p className="feedback-para-text">Email</p>
                  <input
                    className="feedback-email-input"
                    type="email"
                    required
                    onChange={handleEmail}
                    placeholder="Enter your email"
                    value={feedData && feedData.email}
                  ></input>
                </div>
                <div className="feedback-modal-type">
                  <p className="feedback-para-text">Feedback type</p>
                  <div className="feedback-modal-type-innerdiv">
                    <div
                      className={
                        activeType == "general"
                          ? "active-type feedback-type"
                          : "feedback-type"
                      }
                      onClick={() => handleType("general")}
                    >
                      General
                    </div>
                    <div
                      className={
                        activeType == "bug"
                          ? "active-type feedback-type"
                          : "feedback-type"
                      }
                      onClick={() => handleType("bug")}
                    >
                      Bug
                    </div>
                    <div
                      className={
                        activeType == "idea"
                          ? "active-type feedback-type"
                          : "feedback-type"
                      }
                      onClick={() => handleType("idea")}
                    >
                      Idea
                    </div>
                  </div>
                </div>
                <div>
                  {errors && errors.feedback && (
                    <p className="error">
                      {errors &&
                        errors.feedback &&
                        errors.feedback[0] &&
                        errors.feedback[0]}
                    </p>
                  )}
                  <p className="feedback-para-text">Feedback Message</p>
                  <textarea
                    id="feedback_message"
                    name="feedback"
                    className="feedback-textarea"
                    rows="4"
                    cols="50"
                    onChange={handleTextArea}
                  ></textarea>
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn  w-100 feedback-submit-btn"
                    onClick={handleSubmit}
                  >
                    {submitLoading && submitLoading ? (
                      <img
                        src="/images/loadwhite.gif"
                        style={spinnerStyle}
                        alt="loading"
                      />
                    ) : (
                      submitText
                    )}
                  </button>
                </div>
              </div>
            )}
          </OutsideClickHandler>
        </div>
      )}
    </>
  );
}

export default Feedback;
