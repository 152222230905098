import {
	SUGGESTION_ADD_REQUEST,
	SUGGESTION_ADD_SUCCESS,
	SUGGESTION_ADD_FAIL,
	SUGGESTION_ADD_RESET,
	SUGGESTION_LIST_REQUEST,
	SUGGESTION_LIST_SUCCESS,
	SUGGESTION_LIST_FAIL,
	SUGGESTION_PARTICULAR_REQUEST,
	SUGGESTION_PARTICULAR_SUCCESS,
	SUGGESTION_PARTICULAR_FAILED,
	SUGGESTION_EDIT_SUCCESS,
	SUGGESTION_EDIT_FAIL,
	SUGGESTION_EDIT_REQUEST,
	SUGGESTION_EDIT_RESET,
	SUGGESTION_DELETE_SUCCESS,
	SUGGESTION_DELETE_FAIL,
	SUGGESTION_DELETE_REQUEST,
	SUGGESTION_LISTFRONT_REQUEST,
	SUGGESTION_LISTFRONT_SUCCESS,
	SUGGESTION_LISTFRONT_FAIL

} from "../Constants/SuggestionConstants";


export const suggestionFrontReducer = (state = {}, action) => {
	switch (action.type) {
		case SUGGESTION_LISTFRONT_REQUEST:
			return { loading: true }
		case SUGGESTION_LISTFRONT_SUCCESS:
			return { loading: false, suggestion: action.payload }
		case SUGGESTION_LISTFRONT_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const suggestionDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case SUGGESTION_DELETE_REQUEST:
			return { loading: true }
		case SUGGESTION_DELETE_SUCCESS:
			return { loading: false, suggestion: action.payload }
		case SUGGESTION_DELETE_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}


export const suggestionEditReducer = (state = {}, action) => {
	switch (action.type) {
		case SUGGESTION_EDIT_REQUEST:
			return { loading: true }
		case SUGGESTION_EDIT_SUCCESS:
			return { loading: false, suggestion: action.payload }
		case SUGGESTION_EDIT_FAIL:
			return { loading: false, error: action.payload }
		case SUGGESTION_EDIT_RESET:
			return { loading: false, suggestion: {} }
		default:
			return state
	}
}
export const suggestionAddReducer = (state = {}, action) => {
	switch (action.type) {
		case SUGGESTION_ADD_REQUEST:
			return { loading: true }
		case SUGGESTION_ADD_SUCCESS:
			return { loading: false, suggestion: action.payload }
		case SUGGESTION_ADD_FAIL:
			return { loading: false, error: action.payload }
		case SUGGESTION_ADD_RESET:
			return { loading: false, suggestion: {} }
		default:
			return state
	}
}



export const suggestionParticularReducer = (state = {}, action) => {
	switch (action.type) {
		case SUGGESTION_PARTICULAR_REQUEST:
			return { loading: true }
		case SUGGESTION_PARTICULAR_SUCCESS:
			return { loading: false, suggestion: action.payload }
		case SUGGESTION_PARTICULAR_FAILED:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}


export const suggestionListReducer = (state = {}, action) => {
	switch (action.type) {
		case SUGGESTION_LIST_REQUEST:
			return { loading: true }
		case SUGGESTION_LIST_SUCCESS:
			return { loading: false, suggestion: action.payload }
		case SUGGESTION_LIST_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}
