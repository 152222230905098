import {
  ACHIEVEMENTS_ADD,
  ACHIEVEMENTS_UPDATE,
  SAMPLE_ACHIEVEMENTS_ADD,
  SAMPLE_ACHIEVEMENTS_UPDATE,
} from "../Constants/EditorConstants";

import { achievements } from "../../masterData";

export const AchievementsReducers = (state = achievements, action) => {
  switch (action.type) {
    case ACHIEVEMENTS_ADD:
      return action.payload;
    case ACHIEVEMENTS_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

export const sampleAchievementsReducers = (state = achievements, action) => {
  switch (action.type) {
    case SAMPLE_ACHIEVEMENTS_ADD:
      return action.payload;
    case SAMPLE_ACHIEVEMENTS_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
