export const FONTCHANGE_REQUEST = "FONTCHANGE_REQUEST";
export const FONTCHANGE_SUCCESS = "FONTCHANGE_SUCCESS";
export const FONTCHANGE_FAILED = "FONTCHANGE_FAILED";

export const COLORCHANGE_REQUEST = "COLORCHANGE_REQUEST";
export const COLORCHANGE_SUCCESS = "COLORCHANGE_SUCCESS";
export const COLORCHANGE_FAILED = "COLORCHANGE_FAILED";

export const COVER_COLORCHANGE_REQUEST = "COVER_COLORCHANGE_REQUEST";
export const COVER_COLORCHANGE_SUCCESS = "COVER_COLORCHANGE_SUCCESS";
export const COVER_COLORCHANGE_FAILED = "COVER_COLORCHANGE_FAILED";

export const LINEHEIGHTCHANGE_REQUEST = "LINEHEIGHTCHANGE_REQUEST";
export const LINEHEIGHTCHANGE_SUCCESS = "LINEHEIGHTCHANGE_SUCCESS";
export const LINEHEIGHTCHANGE_FAILED = "LINEHEIGHTCHANGE_FAILED";

export const FONTSIZECHANGE_REQUEST = "FONTSIZECHANGE_REQUEST";
export const FONTSIZECHANGE_SUCCESS = "FONTSIZECHANGE_SUCCESS";
export const FONTSIZECHANGE_FAILED = "FONTSIZECHANGE_FAILED";

export const SAMPLE_FONTCHANGE_REQUEST = "SAMPLE_FONTCHANGE_REQUEST";
export const SAMPLE_FONTCHANGE_SUCCESS = "SAMPLE_FONTCHANGE_SUCCESS";
export const SAMPLE_FONTCHANGE_FAILED = "SAMPLE_FONTCHANGE_FAILED";

export const SAMPLE_COLORCHANGE_REQUEST = "SAMPLE_COLORCHANGE_REQUEST";
export const SAMPLE_COLORCHANGE_SUCCESS = "SAMPLE_COLORCHANGE_SUCCESS";
export const SAMPLE_COLORCHANGE_FAILED = "SAMPLE_COLORCHANGE_FAILED";

export const SAMPLE_LINEHEIGHTCHANGE_REQUEST =
  "SAMPLE_LINEHEIGHTCHANGE_REQUEST";
export const SAMPLE_LINEHEIGHTCHANGE_SUCCESS =
  "SAMPLE_LINEHEIGHTCHANGE_SUCCESS";
export const SAMPLE_LINEHEIGHTCHANGE_FAILED = "SAMPLE_LINEHEIGHTCHANGE_FAILED";

export const SAMPLE_FONTSIZECHANGE_REQUEST = "SAMPLE_FONTSIZECHANGE_REQUEST";
export const SAMPLE_FONTSIZECHANGE_SUCCESS = "SAMPLE_FONTSIZECHANGE_SUCCESS";
export const SAMPLE_FONTSIZECHANGE_FAILED = "SAMPLE_FONTSIZECHANGE_FAILED";
