import { useState, createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const TemplateContext = createContext(null);

export const TemplateContextProvider = (props) => {
  const dispatch = useDispatch();
  const [showTemplate, setShowTemplate] = useState(false);
  const [canCalculate, setCanCalculate] = useState(false);

  // For Checking Id
  return (
    <TemplateContext.Provider
      value={{
        showTemplate,
        setShowTemplate,
        canCalculate,
        setCanCalculate,
      }}
    >
      {props.children}
    </TemplateContext.Provider>
  );
};
