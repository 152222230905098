import {
  LAYOUT_ADD,
  CALCULATE_HEIGHT,
  SAMPLE_LAYOUT_ADD,
} from "../Constants/EditorConstants";

const initialState = [
  [
    { section: "profile", page: 0, height: 343, itemIndexes: [0] },
    {
      section: "education",
      page: 0,
      height: 239,
      itemIndexes: [0, 1, 2, 3, 4],
    },
    {
      section: "skills",
      page: 0,
      height: 138,
      itemIndexes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
  ],
  [
    { section: "summary", page: 0, height: 200, itemIndexes: [0] },
    {
      section: "experience",
      page: 0,
      height: 211,
      itemIndexes: [0, 1, 2, 3],
    },
    { section: "languages", page: 0, height: 70, itemIndexes: [0, 1, 2] },
    {
      section: "achievements",
      page: 0,
      height: 70,
      itemIndexes: [0, 1, 2, 3, 4, 5],
    },
  ],
];

export const layoutReducers = (state = [{}], action) => {
  switch (action.type) {
    case LAYOUT_ADD:
      return action.payload;
    default:
      return state;
  }
};
export const calculateHeightReducers = (state = {}, action) => {
  switch (action.type) {
    case CALCULATE_HEIGHT:
      return action.payload;
    default:
      return state;
  }
};

export const sampleLayoutReducers = (state = [{}], action) => {
  switch (action.type) {
    case SAMPLE_LAYOUT_ADD:
      return action.payload;
    default:
      return state;
  }
};
