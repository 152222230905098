import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { useHistory } from "react-router";
import { logout } from "../Store/Actions/authActions";
import { useJwt } from "react-jwt";
import { TemplateContextProvider } from "../Context/templateContext";
import { USER_LOGOUT_RESET } from "../Store/Constants/authConstants";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const userInfo = useSelector((state) => state.userData.userInfo);
  const dispatch = useDispatch();
  const history = useHistory();
  const token = userInfo && userInfo.token;
  const { isExpired } = useJwt(token);
  const logOut = useSelector((state) => state.userLogOut.loginInfo);

  if (!userInfo) {
    history.push("/");
  }

  if (userInfo && isExpired) {
    dispatch(logout());
    history.push("/");
  }

  useEffect(() => {
    if (logOut && logOut.success) {
      history.push("/login");
      dispatch({ type: USER_LOGOUT_RESET });
    }
  }, [logOut]);

  // const stripePromise = loadStripe(
  //   process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  // );

  return (
    <Route
      {...rest}
      render={(props) => (
        // <Elements stripe={stripePromise}>
        <TemplateContextProvider>
          <Component {...props} />
        </TemplateContextProvider>
        // </Elements>
      )}
    />
  );
};
export default PrivateRoute;
