import {
  COVER_CREATE_REQUEST,
  COVER_CREATE_SUCCESS,
  COVER_CREATE_FAIL,
  COVER_CREATE_RESET,
  GET_COVER_REQUEST,
  GET_COVER_SUCCESS,
  GET_COVER_FAIL,
  GET_COVERBYID_SUCCESS,
  GET_COVERBYID_FAIL,
  COVER_UPDATE_REQUEST,
  COVER_UPDATE_SUCCESS,
  COVER_UPDATE_FAIL,
  COVER_UPDATE_RESET,
  COVER_DELETE_REQUEST,
  COVER_DELETE_SUCCESS,
  COVER_DELETE_FAIL,
  COVER_DELETE_RESET,
  COVER_DOWNLOAD_REQUEST,
  COVER_DOWNLOAD_SUCCESS,
  COVER_DOWNLOAD_FAIL,
  COVER_DOWNLOAD_RESET,
  COVER_LAST_UPDATED_AT,
} from "../Constants/CoverLetterConstants";
import { COVER_DATA_ADD, COVER_DATA_RESET } from "../Constants/EditorConstants";
const initialState = {};
export const coverDataReducers = (state = initialState, action) => {
  switch (action.type) {
    case COVER_DATA_ADD:
      return action.payload;
    case COVER_DATA_RESET:
      return {};
    default:
      return state;
  }
};

export const coverLastUpdatedAtReducer = (state = {}, action) => {
  switch (action.type) {
    case COVER_LAST_UPDATED_AT:
      return {
        date: action.payload.data.updated_at,
        title: action.payload.data.title,
      };
    default:
      return state;
  }
};

export const coverDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case COVER_DOWNLOAD_REQUEST:
      return { loading: action.loading };
    case COVER_DOWNLOAD_SUCCESS:
      return { data: action.payload.data };
    case COVER_DOWNLOAD_FAIL:
      return { error: action.payload };
    case COVER_DOWNLOAD_RESET:
      return { data: {} };
    default:
      return state;
  }
};

export const coverDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COVER_DELETE_REQUEST:
      return { loading: action.loading };
    case COVER_DELETE_SUCCESS:
      return { data: action.payload };
    case COVER_DELETE_FAIL:
      return { data: action.payload };
    case COVER_DELETE_RESET:
      return { data: {} };
    default:
      return state;
  }
};

export const coverUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COVER_UPDATE_REQUEST:
      return { loading: action.loading };
    case COVER_UPDATE_SUCCESS:
      return { data: action.payload.data };
    case COVER_UPDATE_FAIL:
      return { data: action.payload };
    case COVER_UPDATE_RESET:
      return { data: {} };
    default:
      return state;
  }
};

export const coverParticularReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COVERBYID_SUCCESS:
      return { coverLetter: action.payload.data };
    case GET_COVERBYID_FAIL:
      return { data: action.payload.data };
    default:
      return state;
  }
};

export const coverListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COVER_REQUEST:
      return { loading: action.loading };
    case GET_COVER_SUCCESS:
      return { coverList: action.payload };
    case GET_COVER_FAIL:
      return { data: action.payload };
    default:
      return state;
  }
};

export const coverCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COVER_CREATE_REQUEST:
      return { loading: action.loading };
    case COVER_CREATE_SUCCESS:
      return { data: action.payload.data };
    case COVER_CREATE_FAIL:
      return { data: action.payload };
    case COVER_CREATE_RESET:
      return { data: {} };
    default:
      return state;
  }
};
