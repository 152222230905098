import {HEADER_FIXED,HEADER_REMOVED,HEADER_FAILED} from '../Constants/HeaderConstants'

const initialState = {
    main:{
        position:"fixed",
    width:'100%',
    zIndex:999,
    top:0,
    height:'60px'
    },
    sub:{
        position:"fixed",
        width:'100%',
        zIndex:99,
        top:'60px',
        height:'48px'
    }
}

export const HeaderReducers = (state = initialState, action) => {
    switch (action.type) {
      case HEADER_FIXED:
        return action.payload;
      case HEADER_REMOVED:
        return action.payload;
        case HEADER_FAILED:
        return action.payload;
      default:
        return state;
    }
  };