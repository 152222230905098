import {
  PLAN_ADD_REQUEST,
  PLAN_ADD_SUCCESS,
  PLAN_ADD_FAIL,
  PLAN_ADD_RESET,
  PLAN_GET__SUCCESS,
  PLAN_GET__REQUEST,
  PLAN_GET__FAIL,
  PLAN_PARTICULAR_REQUEST,
  PLAN_PARTICULAR_SUCCESS,
  PLAN_PARTICULAR_FAILED,
  PLAN_PARTICULAR_RESET,
  PLAN_EDIT_REQUEST,
  PLAN_EDIT_SUCCESS,
  PLAN_EDIT_FAILED,
  PLAN_EDIT_RESET,
  PLAN_DELETE_REQUEST,
  PLAN_DELETE_SUCCESS,
  PLAN_DELETE_FAIL,
  PLAN_SHOW_REQUEST,
  PLAN_SHOW_SUCCESS,
  PLAN_SHOW_FAIL,
  PLAN_SUBMIT_REQUEST,
  PLAN_SUBMIT_SUCCESS,
  PLAN_SUBMIT_FAIL,
  PLAN_SUBMIT_RESET,
  PLAN_VALIDATE_REQUEST,
  PLAN_VALIDATE_SUCCESS,
  PLAN_VALIDATE_FAIL,
  USER_SUBSCRIPTION_REQUEST,
  USER_SUBSCRIPTION_SUCCESS,
  USER_SUBSCRIPTION_FAIL,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL,
  GET_CLIENTSECRET_SUCCESS,
  GET_CLIENTSECRET_FAIL,
  GET_CLIENTSECRET_RESET,
  UPDATE_USERPLAN_SUCCESS,
  UPDATE_USERPLAN_FAIL,
  DEFAULT_CHECKOUT_REQUEST,
  DEFAULT_CHECKOUT_SUCCESS,
  DEFAULT_CHECKOUT_FAIL,
  DEFAULT_CHECKOUT_RESET,
  PLAN_VISIT_SUCCESS,
  PLAN_VISIT_FAIL,
} from "../Constants/PlanConstants";

export const userPlanVisitReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAN_VISIT_SUCCESS:
      return { loading: false, visit: action.payload };
    case PLAN_VISIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const stripeDefaultCheckoutReducer = (state = {}, action) => {
  switch (action.type) {
    case DEFAULT_CHECKOUT_REQUEST:
      return { loading: true };
    case DEFAULT_CHECKOUT_SUCCESS:
      return { loading: false, redirect_url: action.payload };
    case DEFAULT_CHECKOUT_FAIL:
      return { loading: false, error: action.payload };
    case DEFAULT_CHECKOUT_RESET:
      return { loading: false, redirect_url: {} };
    default:
      return state;
  }
};

export const updateUserPlanReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USERPLAN_SUCCESS:
      return { loading: false, userPlan: action.payload };
    case UPDATE_USERPLAN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getClientSecretReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLIENTSECRET_SUCCESS:
      return { loading: false, clientSecret: action.payload };
    case GET_CLIENTSECRET_FAIL:
      return { loading: false, error: action.payload };
    case GET_CLIENTSECRET_RESET:
      return { loading: false, clientSecret: {} };
    default:
      return state;
  }
};

export const cancelSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case CANCEL_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return { loading: false, subscription: action.payload };
    case CANCEL_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case USER_SUBSCRIPTION_SUCCESS:
      return { loading: false, planData: action.payload };
    case USER_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const planValidateReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAN_VALIDATE_REQUEST:
      return { loading: true };
    case PLAN_VALIDATE_SUCCESS:
      return { loading: false, planData: action.payload };
    case PLAN_VALIDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const planSubmitReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAN_SUBMIT_REQUEST:
      return { loading: true };
    case PLAN_SUBMIT_SUCCESS:
      return { loading: false, planData: action.payload };
    case PLAN_SUBMIT_FAIL:
      return { loading: false, error: action.payload };
    case PLAN_SUBMIT_RESET:
      return { loading: false, planData: {} };
    default:
      return state;
  }
};

export const planShowReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAN_SHOW_REQUEST:
      return { loading: true };
    case PLAN_SHOW_SUCCESS:
      return { loading: false, planData: action.payload };
    case PLAN_SHOW_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const planDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAN_DELETE_REQUEST:
      return { loading: true };
    case PLAN_DELETE_SUCCESS:
      return { loading: false, categoryData: action.payload };
    case PLAN_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const planEditReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAN_EDIT_REQUEST:
      return { loading: true };
    case PLAN_EDIT_SUCCESS:
      return { loading: false, categoryData: action.payload };
    case PLAN_EDIT_FAILED:
      return { loading: false, error: action.payload };
    case PLAN_EDIT_RESET:
      return { loading: false, categoryData: {} };
    default:
      return state;
  }
};

export const planParticularReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAN_PARTICULAR_REQUEST:
      return { loading: true };
    case PLAN_PARTICULAR_SUCCESS:
      return { loading: false, categoryData: action.payload };
    case PLAN_PARTICULAR_FAILED:
      return { loading: false, error: action.payload };
    case PLAN_PARTICULAR_RESET:
      return { loading: false, categoryData: {} };
    default:
      return state;
  }
};

export const planAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAN_ADD_REQUEST:
      return { loading: true };
    case PLAN_ADD_SUCCESS:
      return { loading: false, categoryData: action.payload };
    case PLAN_ADD_FAIL:
      return { loading: false, error: action.payload };
    case PLAN_ADD_RESET:
      return { loading: false, categoryData: {} };
    default:
      return state;
  }
};

export const planListReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAN_GET__REQUEST:
      return { loading: true };
    case PLAN_GET__SUCCESS:
      return { loading: false, categoryData: action.payload };
    case PLAN_GET__FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
