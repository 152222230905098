export const SAMPLE_ADD_REQUEST = "SAMPLE_ADD_REQUEST";
export const SAMPLE_ADD_SUCCESS = "SAMPLE_ADD_SUCCESS";
export const SAMPLE_ADD_FAIL = "SAMPLE_ADD_FAIL";
export const SAMPLE_ADD_RESET = "SAMPLE_ADD_RESET";

export const SAMPLE_LIST_REQUEST = "SAMPLE_LIST_REQUEST";
export const SAMPLE_LIST_SUCCESS = "SAMPLE_LIST_SUCCESS";
export const SAMPLE_LIST_FAIL = "SAMPLE_LIST_FAIL";

export const COVER_SAMPLE_LIST_REQUEST = "COVER_SAMPLE_LIST_REQUEST";
export const COVER_SAMPLE_LIST_SUCCESS = "COVER_SAMPLE_LIST_SUCCESS";
export const COVER_SAMPLE_LIST_FAIL = "COVER_SAMPLE_LIST_FAIL";

export const SAMPLE_PARTICULAR_REQUEST = "SAMPLE_PARTICULAR_REQUEST";
export const SAMPLE_PARTICULAR_SUCCESS = "SAMPLE_PARTICULAR_SUCCESS";
export const SAMPLE_PARTICULAR_FAILED = "SAMPLE_PARTICULAR_FAILED";

export const SAMPLE_EDIT_SUCCESS = "SAMPLE_EDIT_SUCCESS";
export const SAMPLE_EDIT_FAIL = "SAMPLE_EDIT_FAIL";
export const SAMPLE_EDIT_RESET = "SAMPLE_EDIT_RESET";
export const SAMPLE_EDIT_REQUEST = "SAMPLE_EDIT_REQUEST";

export const SAMPLE_DELETE_REQUEST = "SAMPLE_DELETE_REQUEST";
export const SAMPLE_DELETE_SUCCESS = "SAMPLE_DELETE_SUCCESS";
export const SAMPLE_DELETE_FAIL = "SAMPLE_DELETE_FAIL";

export const SAMPLE_LISTFRONT_REQUEST = "SAMPLE_LISTFRONT_REQUEST";
export const SAMPLE_LISTFRONT_SUCCESS = "SAMPLE_LISTFRONT_SUCCESS";
export const SAMPLE_LISTFRONT_FAIL = "SAMPLE_LISTFRONT_FAIL";

export const SAMPLE_PREVIEW_REQUEST = "SAMPLE_PREVIEW_REQUEST";
export const SAMPLE_PREVIEW_SUCCESS = "SAMPLE_PREVIEW_SUCCESS";
export const SAMPLE_PREVIEW_FAIL = "SAMPLE_PREVIEW_FAIL";

export const SAMPLE_GENERATE_REQUEST = "SAMPLE_GENERATE_REQUEST";
export const SAMPLE_GENERATE_SUCCESS = "SAMPLE_GENERATE_SUCCESS";
export const SAMPLE_GENERATE_FAIL = "SAMPLE_GENERATE_FAIL";

export const SAMPLE_COUNT_SUCCESS = "SAMPLE_COUNT_SUCCESS";
export const SAMPLE_COUNT_FAILED = "SAMPLE_COUNT_FAILED";

export const GETEXPERIENCE_SUCCESS = "GETEXPERIENCE_SUCCESS";
export const GETEXPERIENCE_FAIL = "GETEXPERIENCE_FAIL";
