import { resumeApi } from "../../Utils/Api";
import axios from "axios";
import {
  LOADING_REQUEST,
  LOADING_SUCCESS,
} from "../Constants/LoadingConstants";
import { updateLocal } from "../../Utils/updateLocalStorage";
import { USER_LOGIN_SUCCESS } from "../Constants/authConstants";
import { logout } from "./authActions";
import {
  USER_GET_SUCCESS,
  USER_GET_FAILED,
  USER_PARTICULAR_FAILED,
  USER_PARTICULAR_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAILED,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED,
  USER_DISCONNECT_SUCCESS,
  USER_DISCONNECT_FAILED,
  USER_SSOUPDATE_SUCCESS,
  USER_SSOUPDATE_FAILED,
  USER_FEEDBACK_SUCCESS,
  USER_FEEDBACK_FAILED,
  ADMIN_FEEDBACK_SUCCESS,
  ADMIN_FEEDBACK_FAILED,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAILED,
  USER_UNSUBSCRIPE_SUCCESS,
  USER_UNSUBSCRIPE_FAILED,
} from "../Constants/UserConstants";
import { JOBTITLE_STORE_SUCCESS } from "../Constants/JobConstants";
const baseUrl = process.env.REACT_APP_API_URL;

export const unsubscripe = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${baseUrl}/api/unsubscripe/${email}`,
      config
    );

    dispatch({
      type: USER_UNSUBSCRIPE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: USER_UNSUBSCRIPE_FAILED,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const verifyUser = (email, code) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const { jwtGenerate } = getState();
    let token =
      jwtGenerate && jwtGenerate.data && jwtGenerate && jwtGenerate.data.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${baseUrl}/api/verify/${email}/${code}`,
      config
    );
    const userInfo = {
      token: data.token,
      user: data.data,
    };

    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    localStorage.removeItem("guestToken");

    dispatch({
      type: USER_VERIFY_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: userInfo,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: USER_VERIFY_FAILED,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const feedback = (feed) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`${baseUrl}/api/feedback`, feed, config);

    dispatch({
      type: USER_FEEDBACK_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: USER_FEEDBACK_FAILED,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const ssoUpdate =
  (account, secretCode, provider) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });

      const {
        userData: { userInfo },
      } = getState();

      const userId = userInfo.user.id;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseUrl}/api/ssoupdate/${userId}/${account}/callback/${secretCode}`,
        config
      );

      dispatch({
        type: USER_SSOUPDATE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: USER_SSOUPDATE_FAILED,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const ssoDisconnect = (sso) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${baseUrl}/api/user/disconnect/${sso}/${userId}`,
      config
    );

    dispatch({
      type: USER_DISCONNECT_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: USER_DISCONNECT_FAILED,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};
export const socialLogin =
  (token, isAdmin = false) =>
  async (dispatch) => {
    const url = `api/auth/sociallogin`;
    const admin = isAdmin ? true : false;
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });

      resumeApi(url, "POST", { token }, admin).then((result) => {
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("user", JSON.stringify(result.data.user));
        document.cookie = `logged=true; path=/; secure; domain=qwikresume.com; expires=${new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24 * 365
        ).toGMTString()}`;

        dispatch({
          type: SOCIAL_LOGIN_SUCCESS,
          payload: result.data,
        });
        dispatch({
          type: LOADING_SUCCESS,
          loading: false,
        });
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: SOCIAL_LOGIN_FAILED,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const changePassword = (password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${baseUrl}/api/user/changepassword`,
      password,
      config
    );

    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: data,
    });

    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });

    dispatch({
      type: CHANGE_PASSWORD_FAILED,
      payload:
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : error.response.data.message,
    });
  }
};

export const userUpdate = (updatedata) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${baseUrl}/api/Edit/${userId}`,
      updatedata,
      config
    );

    if (data && data.data && data.data != "") {
      updateLocal(data && data.data && data.data);
    }

    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: USER_UPDATE_FAILED,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const userParticularGet = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${baseUrl}/api/user/${userId}`, config);

    if (data && data.invalid) {
      dispatch(logout());
    }
    dispatch({
      type: USER_PARTICULAR_SUCCESS,
      payload: data,
    });

    let local_jobtitle = JSON.parse(localStorage.getItem("user_settings"));
    if (local_jobtitle) {
      if (data && data.user) {
        local_jobtitle.accountSettings = {
          job_title: data.user.job_title,
          job_category: data.user.job_category,
        };
      }
    } else {
      local_jobtitle = {};
      if (data && data.user) {
        local_jobtitle.accountSettings = {
          job_title: data.user.job_title,
          job_category: data.user.job_category,
        };
      }
    }

    localStorage.setItem("user_settings", JSON.stringify(local_jobtitle));
    if (data && data.user) {
      localStorage.setItem(
        "document_size",
        JSON.stringify(data.user.document_size ? data.user.document_size : "a4")
      );
    }

    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: USER_PARTICULAR_FAILED,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const userDelete = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${baseUrl}/api/Delete/${userId}`,
      config
    );

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: USER_DELETE_FAILED,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};
