import React from "react";
import "./Rating.scss";
import { useHistory } from "react-router";
import Modal from "react-bootstrap/Modal";
function Profeature(props) {
  const history = useHistory();
  const handlePayment = () => {
    history.push("/pricingplans");
  };

  const handleRedirect = () => {
    window.open(
      "https://www.trustpilot.com/evaluate/qwikresume.com",
      "_blank",
      "noreferrer"
    );
  };
  return (
    <>
      <Modal
        {...props}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="trustpilot-rating-modal"
      >
        <Modal.Body>
          <img
            onClick={props.onHide}
            src={`${process.env.REACT_APP_COMMON_ASSET_PATH}/close.svg`}
            alt="closeicon"
            className="close"
          />
          <div className="trustpilot-modal-wrapper">
            <img src="./images/emoji.svg" />
            <h2>Spread the Word</h2>
            <p>
              We value your feedback and appreciate your time in leaving a
              rating and review.
            </p>
            <div id="rating_bar" onClick={handleRedirect}>
              <button className="btn btn-prmiary">Rate Your Experience</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <p className="px-3 mb-0 trustpilot-modal-footer-text ">
              You will be redirected to our TrustPilot page to complete the
              rating.{" "}
            </p>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Profeature;
