import { useState, createContext, useEffect } from "react";
import Feedback from "../Components/Feedback/Feedback";
import RatingModal from "../Components/TrustpilotRating/Rating";

export const pageLayoutContext = createContext(null);

export const PageLayoutContextProvider = (props) => {
  const [pageBrake, setPageBrake] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reArrangeStatus, setReArrangeStatus] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const path = window.location.pathname;

  useEffect(() => {
    var isMobileDevice =
      navigator.userAgent.match(/iPad|iPhone|iPod/i) != null ||
      window.screen.width <= 480;
    setIsMobile(isMobileDevice);
  }, []);

  const handleModal = () => {
    setShowModal(!showModal);
  };
  const handleHide = () => {
    setShowModal(false);
  };
  return (
    <>
      {path && path.includes("preview") ? (
        ""
      ) : path && path.includes("signup") ? (
        ""
      ) : path && path.includes("login") ? (
        ""
      ) : path && path.includes("forgot_password") ? (
        ""
      ) : path && path.includes("change_password") ? (
        ""
      ) : path && path.includes("qr") ? (
        ""
      ) : path && path.includes("admin") ? (
        ""
      ) : (
        <>
          {/* <div className="rating-btn" onClick={handleModal}>
            <i class="fa fa-smile-o rating-smile" aria-hidden="true"></i>
          </div> */}
          <Feedback
            showFeedback={showFeedback}
            setShowFeedback={setShowFeedback}
          />
        </>
      )}
      {/* <RatingModal show={showModal} onHide={handleHide} /> */}
      <pageLayoutContext.Provider
        value={{
          pageBrake,
          setPageBrake,
          reArrangeStatus,
          setReArrangeStatus,
          showFeedback,
          setShowFeedback,
          isMobile,
          setIsMobile,
        }}
      >
        {props.children}
      </pageLayoutContext.Provider>
    </>
  );
};
