import {
  FROM_ADD,
  FROM_UPDATE,
  COVERJOB_ISPRESENT_REQUEST,
  COVERJOB_ISPRESENT_SUCCESS,
  COVERJOB_ISPRESENT_FAIL,
  COVERJOB_ISPRESENT_RESET,
} from "../Constants/EditorConstants";
const initialState = {
  name: "",
  jobTitle: "",
  address: "",
  email: "",
  phone: "",
  city: "",
  website: "",
};

const isPresent = {
  data: false,
  isJob: false,
};

export const isCoverJobPresentReducers = (state = isPresent, action) => {
  switch (action.type) {
    case COVERJOB_ISPRESENT_REQUEST:
      return { loading: true };
    case COVERJOB_ISPRESENT_SUCCESS:
      return action.payload.data;
    case COVERJOB_ISPRESENT_FAIL:
      return { loading: false, error: action.payload };
    case COVERJOB_ISPRESENT_RESET:
      return {};
    default:
      return state;
  }
};

export const fromReducers = (state = initialState, action) => {
  switch (action.type) {
    case FROM_ADD:
      return action.payload;
    case FROM_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
