import {
  JOB_LIST_REQUEST,
  JOB_LIST_SUCCESS,
  JOB_LIST_FAIL,
  CATEGORY_BASED_REQUEST,
  CATEGORY_BASED_SUCCESS,
  CATEGORY_BASED_FAIL,
} from "../Constants/JobConstants";

export const categoryBasedReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_BASED_REQUEST:
      return { loading: true };
    case CATEGORY_BASED_SUCCESS:
      return { loading: false, job: action.payload };
    case CATEGORY_BASED_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const jobListReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_LIST_REQUEST:
      return { loading: true };
    case JOB_LIST_SUCCESS:
      return { loading: false, job: action.payload };
    case JOB_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
