export const TEMPLATE_GET_SUCCESS = "TEMPLATE_GET_SUCCESS";
export const TEMPLATE_GET_FAILED = "TEMPLATE_GET_FAILED";
export const TEMPLATE_GET_RESET = "TEMPLATE_GET_RESET";





export const COVERTEMPLATE_UPDATE_SUCCESS = "COVERTEMPLATE_UPDATE_SUCCESS";
export const COVERTEMPLATE_UPDATE_FAIL = "COVERTEMPLATE_UPDATE_FAIL";
export const COVERTEMPLATE_UPDATE_RESET = "COVERTEMPLATE_UPDATE_RESET";

export const GET_TEMPLATE_ID = "GET_TEMPLATE_ID";

export const CHANGE_TEMPLATE_SUCCESS = "CHANGE_TEMPLATE_SUCCESS";


export const TEMPLATE_EDIT_SUCCESS = "TEMPLATE_EDIT_SUCCESS";
export const TEMPLATE_EDIT_FAILED = "TEMPLATE_EDIT_FAILED";

export const TEMPLATE_UPDATE_SUCCESS = "TEMPLATE_UPDATE_SUCCESS";
export const TEMPLATE_UPDATE_RESET = "TEMPLATE_UPDATE_RESET";

export const TEMPLATE_UPDATE_FAIL = "TEMPLATE_UPDATE_FAIL";

export const TEMPLATE_ADD_SUCCESS = "TEMPLATE_ADD_SUCCESS";
export const TEMPLATE_ADD_FAILED = "TEMPLATE_ADD_FAILED";



export const TEMPLATE_GET_PARTICULAR_SUCCESS =
  "TEMPLATE_GET_PARTICULAR_SUCCESS";
export const TEMPLATE_GET_PARTICULAR_FAILED = "TEMPLATE_GET_PARTICULAR_FAILED";

export const TEMPLATE_COUNT_SUCCESS = "TEMPLATE_COUNT_SUCCESS";
export const TEMPLATE_COUNT_FAILED = "TEMPLATE_COUNT_FAILED";

export const COVER_TEMPLATE_GET_PARTICULAR_SUCCESS =
  "COVER_TEMPLATE_GET_PARTICULAR_SUCCESS";
export const COVER_TEMPLATE_GET_PARTICULAR_FAILED =
  "COVER_TEMPLATE_GET_PARTICULAR_FAILED";

export const COVER_TEMPLATE_GET_SUCCESS = "COVER_TEMPLATE_GET_SUCCESS";
export const COVER_TEMPLATE_GET_FAILED = "COVER_TEMPLATE_GET_FAILED";
