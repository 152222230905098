import axios from "axios";
import {
  LOADING_REQUEST,
  LOADING_SUCCESS,
  RESUME_LOADING_REQUEST,
  RESUME_LOADING_SUCCESS,
  EDITOR_LOADING_REQUEST,
  EDITOR_LOADING_SUCCESS,
  EDITOR_LOADING_FAIL,
} from "../Constants/LoadingConstants";
import { coverTemplateGetParticularActions } from "./TemplateActions";
import { isJobPresent } from "./ProfileActions";
import { updateLocal } from "../../Utils/updateLocalStorage";
import { isCoverJobPresent } from "./FromActions";

import {
  COVER_CREATE_REQUEST,
  COVER_CREATE_SUCCESS,
  COVER_CREATE_FAIL,
  GET_COVER_REQUEST,
  GET_COVER_SUCCESS,
  GET_COVER_FAIL,
  GET_COVERBYID_SUCCESS,
  GET_COVERBYID_FAIL,
  COVER_UPDATE_REQUEST,
  COVER_UPDATE_SUCCESS,
  COVER_UPDATE_FAIL,
  COVER_DELETE_REQUEST,
  COVER_DELETE_SUCCESS,
  COVER_DELETE_FAIL,
  COVER_DOWNLOAD_REQUEST,
  COVER_DOWNLOAD_SUCCESS,
  COVER_DOWNLOAD_FAIL,
  COVER_LAST_UPDATED_AT,
} from "../Constants/CoverLetterConstants";

import {
  FROM_ADD,
  TO_ADD,
  CONTENT_ADD,
  SETTINGS_ADD,
  UNDOREDO_ADD_COVER,
  COVER_DATA_ADD,
  COVER_DATA_RESET,
} from "../Constants/EditorConstants";

import { COVER_COLORCHANGE_SUCCESS } from "../Constants/CustomizationConstants";

const baseurl = process.env.REACT_APP_API_URL;
const path = window.location.pathname;

export const downloadCover = (id, download) => async (dispatch, getState) => {
  try {
    // dispatch({
    //   type: LOADING_REQUEST,
    //   loading: true,
    // });

    dispatch({
      type: COVER_DOWNLOAD_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    let data;
    if (download == "download") {
      data = await axios.get(
        `${baseurl}/api/cover/image/${userId}/${id}/download`,
        config
      );
      dispatch({
        type: COVER_DOWNLOAD_SUCCESS,
        payload: data,
      });
    } else {
      data = await axios.get(
        `${baseurl}/api/cover/image/${userId}/${id}`,
        config
      );
      dispatch({
        type: COVER_DOWNLOAD_SUCCESS,
        payload: data,
      });
    }
    // dispatch({
    //   type: LOADING_SUCCESS,
    //   loading: false,
    // });
  } catch (error) {
    // dispatch({
    //   type: LOADING_SUCCESS,
    //   loading: false,
    // });

    dispatch({
      type: COVER_DOWNLOAD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const deleteCoverByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });
    dispatch({
      type: COVER_DELETE_REQUEST,
      loading: true,
    });
    const {
      userData: { userInfo },
    } = getState();
    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.delete(
      `${baseurl}/api/cover/${userId}/${id}`,
      config
    );

    dispatch({
      type: COVER_DELETE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: COVER_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const coverEditActions =
  (id, content, customization) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EDITOR_LOADING_REQUEST,
        loading: true,
      });
      dispatch({
        type: COVER_UPDATE_REQUEST,
        loading: true,
      });
      const {
        userData: { userInfo },
        undoRedoCover,
      } = getState();
      if (path && path.includes("create") && customization != "undo") {
        let undodata = undoRedoCover;
        undodata.history.timeline.push({
          editorData: JSON.parse(content.content),
        });
        undodata.history.currentIndex = undodata.history.timeline.length - 1;
        dispatch({
          type: UNDOREDO_ADD_COVER,
          payload: undodata,
        });

        if (customization == "sizeHandle") {
          let resData = {};
          resData.data = content;
          dispatch({
            type: GET_COVERBYID_SUCCESS,
            payload: resData,
          });
        }
      }

      const userId = userInfo.user.id;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${baseurl}/api/cover/${userId}/${id}`,
        content,
        config
      );
      dispatch({
        type: COVER_UPDATE_SUCCESS,
        payload: data,
      });

      dispatch({
        type: GET_COVERBYID_SUCCESS,
        payload: data,
      });

      if (customization != "undo") {
        let undodata = undoRedoCover;
        undodata.history.timeline.push({
          editorData: JSON.parse(content.content),
        });
        undodata.history.currentIndex = undodata.history.timeline.length - 1;
        dispatch({
          type: UNDOREDO_ADD_COVER,
          payload: undodata,
        });
      }
      // setTimeout(() => {
      dispatch({
        type: EDITOR_LOADING_SUCCESS,
        loading: false,
      });
      // }, [2000]);
      dispatch({
        type: COVER_LAST_UPDATED_AT,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EDITOR_LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: COVER_UPDATE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const sampleCoverById =
  (id, editData, isLogin) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });

      const {
        userData: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (userInfo) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${userInfo.token}`,
        };
      }

      if (isLogin != "isAuth") {
        var url = "";

        url = `${baseurl}/api/sampleCover/detail/${id}`;

        const { data } = await axios.get(url, config);

        dispatch(coverTemplateGetParticularActions(data.data.template_id));

        const editorData = JSON.parse(data.data.content);
        if (
          data &&
          data.data &&
          data.data.type &&
          data.data.type === "cover_letter"
        ) {
          const undoRedoInitial = {
            history: {
              timeline: [{ editorData }],
              currentIndex: 0,
            },
          };
          dispatch({
            type: UNDOREDO_ADD_COVER,
            payload: undoRedoInitial,
          });

          dispatch({
            type: COVER_DATA_ADD,
            payload: editorData,
          });

          const user_settings = {
            job_title: data && data.data && data.data.job_title,
            job_category: data && data.data && data.data.category,
          };
          if (
            data &&
            data.data &&
            data.data.job_title &&
            data.data.job_title != ""
          ) {
            updateLocal(user_settings);
          }

          //   let tempData = data;
          //   tempData["data"]["template_id"] = editorData.template_id;
          dispatch({
            type: GET_COVERBYID_SUCCESS,
            payload: data,
          });
        } else {
          //   const user_settings = {
          //     job_title: data && data.data && data.data.job_title,
          //     job_category: data && data.data && data.data.original_template_id,
          //   };
          //   dispatch({
          //     type: GET_COVERBYID_SUCCESS,
          //     payload: data,
          //   });
        }
        dispatch({
          type: COVER_LAST_UPDATED_AT,
          payload: data,
        });
        dispatch({
          type: LOADING_SUCCESS,
          loading: false,
        });
      } else {
        var url = "";

        url = `${baseurl}/api/sampleCover/detail/${id}`;

        const { data } = await axios.get(url, config);
        //   dispatch(templateGetParticularActions(editData.template_id));

        // const editorData = JSON.parse(data.data.content);
        if (
          data &&
          data.data &&
          data.data.type &&
          data.data.type === "cover_letter"
        ) {
          let tempData = data;
          tempData["data"]["template_id"] = editData.template_id;
          dispatch({
            type: GET_COVERBYID_SUCCESS,
            payload: tempData,
          });
        } else {
          let tempData = data;
          dispatch({
            type: GET_COVERBYID_SUCCESS,
            payload: tempData,
          });
        }
        dispatch({
          type: COVER_LAST_UPDATED_AT,
          payload: data,
        });
        dispatch({
          type: LOADING_SUCCESS,
          loading: false,
        });
      }
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: GET_COVERBYID_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const getCoverById =
  (id, share, withoutid) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });
      // dispatch({
      // 	type: GET_RESUMEBYID_REQUEST,
      // 	loading: true
      // })

      const {
        userData: { userInfo },
      } = getState();

      const userId = userInfo && userInfo.user.id;

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (userInfo) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${userInfo.token}`,
        };
      }
      var url = "";
      if (withoutid) {
        url = `${baseurl}/api/cover/detail/${id}`;
      } else if (userId) {
        if (share == "qrcover") {
          url = `${baseurl}/api/cover/${userId}/${id}?share=${true}`;
        } else {
          url = `${baseurl}/api/cover/${userId}/${id}`;
        }
      } else {
        if (share == "qrcover") {
          // url = `${baseurl}/api/resume/${userId}/${id}?share=${true}`
          url = `${baseurl}/api/cover/detail/${id}?share=${true}`;
        } else {
          url = `${baseurl}/api/cover/detail/${id}`;
        }
      }
      const { data } = await axios.get(url, config);

      dispatch(
        coverTemplateGetParticularActions(
          data && data.data && data.data.template_id
        )
      );

      const editorData = JSON.parse(data.data.content);
      dispatch({
        type: COVER_DATA_ADD,
        payload: editorData,
      });

      if (share != "undo") {
        const undoRedoInitial = {
          history: {
            timeline: [{ editorData }],
            currentIndex: 0,
          },
        };
        dispatch({
          type: UNDOREDO_ADD_COVER,
          payload: undoRedoInitial,
        });
      }

      dispatch({
        type: COVER_LAST_UPDATED_AT,
        payload: data,
      });

      dispatch({
        type: GET_COVERBYID_SUCCESS,
        payload: data,
      });
      if (editorData && editorData.from) {
        dispatch(
          isCoverJobPresent(
            editorData && editorData.from && editorData.from.jobTitle
          )
        );
      }
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: GET_COVERBYID_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const getAllCoverLetters =
  (sort, method) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });
      dispatch({
        type: RESUME_LOADING_REQUEST,
        loading: true,
      });
      dispatch({
        type: GET_COVER_REQUEST,
        loading: true,
      });

      const {
        userData: { userInfo },
      } = getState();

      const userId = userInfo.user.id;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      if (sort == "infinite") {
        const { data } = await axios.get(
          `${baseurl}/api/cover/userall/${userId}?page=${method}`,
          config
        );
        let homeResumes = {};
        homeResumes.data = [];

        homeResumes.total = data && data.data.total;
        homeResumes.next_page_url = data && data.data.next_page_url;
        homeResumes.current_page = data && data.data.current_page;
        homeResumes.last_page = data && data.data.last_page;
        homeResumes.last_page_url = data && data.data.last_page_url;
        if (data && data.data && data.data.data) {
          data.data.data.forEach((res) => {
            let editorData = JSON.parse(res.content);
            editorData.type = res.type;
            editorData.id = res.id;
            editorData.title = res.title;
            editorData.updated_at = res.updated_at;
            editorData.template_id = res.template_id;
            editorData.random_code = res.random_code;
            homeResumes.data.push(editorData);
          });
        } else {
          // If data or its nested properties are not present, keep the array empty
          homeResumes.data = [];
        }

        dispatch({
          type: GET_COVER_SUCCESS,
          payload: homeResumes,
        });
        dispatch({
          type: LOADING_SUCCESS,
          loading: false,
        });
        dispatch({
          type: RESUME_LOADING_SUCCESS,
          loading: false,
        });
      } else if (
        sort != "initial" &&
        sort !== "" &&
        method != "search" &&
        method != ""
      ) {
        const { data } = await axios.get(
          `${baseurl}/api/cover/search/${userId}/?sort=${sort}&direction=${method}`,
          config
        );
        let homeResumes = {};
        homeResumes.data = [];
        homeResumes.total = data && data.data.total;
        homeResumes.next_page_url = data && data.data.next_page_url;
        homeResumes.current_page = data && data.data.current_page;
        homeResumes.last_page = data && data.data.last_page;
        homeResumes.last_page_url = data && data.data.last_page_url;
        if (data && data.data && data.data.data) {
          data.data.data.forEach((res) => {
            let editorData = JSON.parse(res.content);
            editorData.type = res.type;
            editorData.id = res.id;
            editorData.title = res.title;
            editorData.updated_at = res.updated_at;
            editorData.template_id = res.template_id;
            editorData.random_code = res.random_code;
            homeResumes.data.push(editorData);
          });
        } else {
          // If data or its nested properties are not present, keep the array empty
          homeResumes.data = [];
        }
        
        dispatch({
          type: GET_COVER_SUCCESS,
          payload: homeResumes,
        });
        dispatch({
          type: LOADING_SUCCESS,
          loading: false,
        });
        dispatch({
          type: RESUME_LOADING_SUCCESS,
          loading: false,
        });
      } else if (
        sort != "initial" &&
        sort &&
        sort.length > 0 &&
        method == "search"
      ) {
        const { data } = await axios.get(
          `${baseurl}/api/cover/search/${userId}?search=${sort}`,
          config
        );
        let homeResumes = {};
        homeResumes.data = [];
        homeResumes.total = data && data.data.total;
        homeResumes.next_page_url = data && data.data.next_page_url;
        homeResumes.current_page = data && data.data.current_page;
        homeResumes.last_page = data && data.data.last_page;
        homeResumes.last_page_url = data && data.data.last_page_url;
        if (data && data.data && data.data.data) {
          data.data.data.forEach((res) => {
            let editorData = JSON.parse(res.content);
            editorData.type = res.type;
            editorData.id = res.id;
            editorData.title = res.title;
            editorData.updated_at = res.updated_at;
            editorData.template_id = res.template_id;
            editorData.random_code = res.random_code;
            homeResumes.data.push(editorData);
          });
        } else {
          // If data or its nested properties are not present, keep the array empty
          homeResumes.data = [];
        }
        
        dispatch({
          type: GET_COVER_SUCCESS,
          payload: homeResumes,
        });
        dispatch({
          type: LOADING_SUCCESS,
          loading: false,
        });
        dispatch({
          type: RESUME_LOADING_SUCCESS,
          loading: false,
        });
      } else {
        const { data } = await axios.get(
          `${baseurl}/api/cover/userall/${userId}`,
          config
        );
        let homeResumes = {};
        homeResumes.data = [];
        homeResumes.total = data && data.data.total;
        homeResumes.next_page_url = data && data.data.next_page_url;
        homeResumes.current_page = data && data.data.current_page;
        homeResumes.last_page = data && data.data.last_page;
        homeResumes.last_page_url = data && data.data.last_page_url;
        if (data && data.data && data.data.data) {
          data.data.data.forEach((res) => {
            let editorData = JSON.parse(res.content);
            editorData.type = res.type;
            editorData.id = res.id;
            editorData.title = res.title;
            editorData.updated_at = res.updated_at;
            editorData.template_id = res.template_id;
            editorData.random_code = res.random_code;
            homeResumes.data.push(editorData);
          });
        } else {
          // If data or its nested properties are not present, keep the array empty
          homeResumes.data = [];
        }
        
        dispatch({
          type: GET_COVER_SUCCESS,
          payload: homeResumes,
        });

        dispatch({
          type: LOADING_SUCCESS,
          loading: false,
        });
        dispatch({
          type: RESUME_LOADING_SUCCESS,
          loading: false,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_COVER_FAIL,
        payload: error.message,
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: RESUME_LOADING_SUCCESS,
        loading: false,
      });
    }
  };

export const createCoverLetter =
  (content, userFields) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });

      dispatch({
        type: COVER_CREATE_REQUEST,
        loading: true,
      });

      const {
        userData: { userInfo },
      } = getState();

      const userId = userFields
        ? userFields && userFields.data.id
        : userInfo.user.id;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            userFields ? userFields.token : userInfo.token
          }`,
        },
      };
      const document_size = JSON.parse(localStorage.getItem("document_size"));
      let createData = content;
      createData.isLetter = document_size && document_size == "a4" ? 0 : 1;

      const { data } = await axios.post(
        `${baseurl}/api/cover/${userId}`,
        createData,
        config
      );

      dispatch({
        type: COVER_CREATE_SUCCESS,
        payload: data,
      });

      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });

      dispatch({
        type: COVER_CREATE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };
