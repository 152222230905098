import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { useHistory } from "react-router";
import { logout } from "../Store/Actions/authActions";
import { useJwt } from "react-jwt";
import { TemplateContextProvider } from "../Context/templateContext";
import { USER_LOGOUT_RESET } from "../Store/Constants/authConstants";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userInfo = useSelector((state) => state.userData.userInfo);
  const dispatch = useDispatch();
  const history = useHistory();
  const token = userInfo && userInfo.token;
  const { isExpired } = useJwt(token);
  const logOut = useSelector((state) => state.userLogOut.loginInfo);
  // const path = window.location.pathname;

  // function check_cookie_name(name)
  //   {
  //     var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  //     if (match) {
  //     }
  //     else{
  //       if(path&&path.includes('login')){

  //       }else if(path&&path.includes('signup')){

  //       }else if(path&&path.includes('preview')){

  //       }else if(path&&path.includes('forgot_password')){

  //       }else if(path&&path.includes('change_password')){

  //       }else if(path&&path.includes('qr')){

  //       }else{
  //         history.push("/onboarding");

  //       }
  //     }
  //  }
  //  useEffect(() => {
  //   check_cookie_name('logged')
  //  }, [])

  if (!userInfo) {
    history.push("/");
  }

  if (userInfo && isExpired) {
    dispatch(logout());
    history.push("/");
  }

  useEffect(() => {
    if (logOut && logOut.success) {
      history.push("/login");
      dispatch({ type: USER_LOGOUT_RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOut]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <TemplateContextProvider>
          <Component {...props} />
        </TemplateContextProvider>
      )}
    />
  );
};
export default PrivateRoute;
