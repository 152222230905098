import { SKILLS_ADD, SKILLS_UPDATE, SAMPLE_SKILLS_ADD, SAMPLE_SKILLS_UPDATE } from "../Constants/EditorConstants";
import { skills } from '../../masterData';


export const SkillsReducers = (state = skills, action) => {
	switch (action.type) {
		case SKILLS_ADD:
			return action.payload;
		case SKILLS_UPDATE:
			return action.payload;
		default:
			return state;
	}
};

export const sampleSkillsReducers = (state = skills, action) => {
	switch (action.type) {
		case SAMPLE_SKILLS_ADD:
			return action.payload;
		case SAMPLE_SKILLS_UPDATE:
			return action.payload;
		default:
			return state;
	}
};
