import "./App.css";
import React, { useState, useEffect, useContext, useCallback } from "react";
import store from "./Store/store";
import { Provider } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import GoogleFontLoader from "react-google-font";

import Routes from "./Components/Routes";

import { userParticularGet } from "./Store/Actions/UserActions";
import { clarity } from "react-microsoft-clarity";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

function App() {
  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const path = window.location.pathname;
  const query = new URLSearchParams(window.location.search);
  const refer = query.get("refer") ? query.get("refer") : "App";
  if ("refer" in localStorage) {
    const local_refer = localStorage.getItem("refer");
    if (local_refer == "null" || refer != "App") {
      localStorage.setItem("refer", refer);
    }
  } else {
    localStorage.setItem("refer", refer);
  }

  if (
    (path && path == "/") ||
    (path && path.includes("home")) ||
    (path && path.includes("myaccount"))
  ) {
  } else {
    store.dispatch(userParticularGet());
  }

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    clarity.init("fhrmng1d09");
  }, []);

  // Sentry.init({
  //   dsn: "https://9340f3da6f554b12af58011994be64a0@o4504185279021056.ingest.sentry.io/4504185280724992",
  //   integrations: [new BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });

  var referrer = document.referrer;

  return (
    <>
      {isOnline && isOnline ? (
        <>
          <Provider store={store}>
            {/* <GoogleFontLoader
              fonts={[
                {
                  font: "Montserrat",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Raleway",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Rubik",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Open Sans",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Poppins",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Lato",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Fira Sans",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "PT Sans",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "PT Sans Caption",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Source Sans Pro",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Lora",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Yantramanav",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Quicksand",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Ubuntu",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Oswald",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Roboto",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Roboto Condensed",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "Hind",
                  weights: [400, 500, 600, 700],
                },
                {
                  font: "OverPass",
                  weights: [400, 500, 600, 700],
                },
              ]}
            /> */}
            <Routes />
          </Provider>
        </>
      ) : (
        <>
          <div class="offline-wrapper">
            <h1 className="offline-h1">you're offline!</h1>
            <h4 className="offline-h4 py-3">
              Please check your internet connection
            </h4>

            <a href="." className="offline-button">
              RETRY
            </a>
          </div>
        </>
      )}
    </>
  );
}

export default App;
