import {
  INTEREST_ADD,
  INTEREST_UPDATE,
  SAMPLE_INTEREST_ADD,
  SAMPLE_INTEREST_UPDATE,
} from "../Constants/EditorConstants";

import { interests } from "../../masterData";

export const InterestsReducers = (state = interests, action) => {
  switch (action.type) {
    case INTEREST_ADD:
      return action.payload;
    case INTEREST_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

export const sampleInterestsReducers = (state = interests, action) => {
  switch (action.type) {
    case SAMPLE_INTEREST_ADD:
      return action.payload;
    case SAMPLE_INTEREST_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
