import {
  TEMPLATE_GET_SUCCESS,
  TEMPLATE_GET_FAILED,
  TEMPLATE_GET_RESET,
  GET_TEMPLATE_ID,
  TEMPLATE_ADD_SUCCESS,
  TEMPLATE_ADD_FAILED,
  TEMPLATE_GET_PARTICULAR_SUCCESS,
  TEMPLATE_GET_PARTICULAR_FAILED,
  TEMPLATE_COUNT_SUCCESS,
  TEMPLATE_COUNT_FAILED,
  ADMIN_TEMPLATE_REQUEST,
  ADMIN_TEMPLATE_SUCCESS,
  ADMIN_TEMPLATE_FAIL,
  TEMPLATE_ADMINPARTICULAR_SUCCESS,
  TEMPLATE_ADMINPARTICULAR_FAIL,
  TEMPLATE_UPDATE_SUCCESS,
  TEMPLATE_UPDATE_RESET,
  TEMPLATE_UPDATE_FAIL,
  COVER_TEMPLATE_GET_PARTICULAR_SUCCESS,
  COVER_TEMPLATE_GET_PARTICULAR_FAILED,
  COVER_TEMPLATE_GET_SUCCESS,
  COVER_TEMPLATE_GET_FAILED,
  ADMIN_COVERTEMPLATE_REQUEST,
  ADMIN_COVERTEMPLATE_SUCCESS,
  ADMIN_COVERTEMPLATE_FAIL,
  COVERTEMPLATE_ADMINPARTICULAR_SUCCESS,
  COVERTEMPLATE_ADMINPARTICULAR_FAIL,
  COVERTEMPLATE_UPDATE_SUCCESS,
  COVERTEMPLATE_UPDATE_FAIL,
  COVERTEMPLATE_UPDATE_RESET,
} from "../Constants/TemplateConstants";






export const CoverTemplateReducers = (state = {}, action) => {
  switch (action.type) {
    case COVER_TEMPLATE_GET_SUCCESS:
      return { ...state, loading: action.loading, list: action.payload.data };
    case GET_TEMPLATE_ID:
      return { ...state, templateId: action.payload };
    case COVER_TEMPLATE_GET_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export const CoverTemplateParticularReducers = (state = {}, action) => {
  switch (action.type) {
    case COVER_TEMPLATE_GET_PARTICULAR_SUCCESS:
      return action.payload;
    case GET_TEMPLATE_ID:
      return { ...state, templateId: action.payload };
    case COVER_TEMPLATE_GET_PARTICULAR_FAILED:
      return action.payload;
    default:
      return state;
  }
};





export const TemplateCountAddReducers = (state = {}, action) => {
  switch (action.type) {
    case TEMPLATE_COUNT_SUCCESS:
      return { data: action.payload.data };
    case TEMPLATE_COUNT_FAILED:
      return { data: action.payload };
    default:
      return state;
  }
};

export const TemplateParticularReducers = (state = {}, action) => {
  switch (action.type) {
    case TEMPLATE_GET_PARTICULAR_SUCCESS:
      return action.payload;
    case GET_TEMPLATE_ID:
      return { ...state, templateId: action.payload };
    case TEMPLATE_GET_PARTICULAR_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export const TemplateReducers = (state = {}, action) => {
  switch (action.type) {
    case TEMPLATE_GET_SUCCESS:
      return { ...state, loading: action.loading, list: action.payload.data };
    case GET_TEMPLATE_ID:
      return { ...state, templateId: action.payload };
    case TEMPLATE_GET_FAILED:
      return action.payload;
    case TEMPLATE_GET_RESET:
      return {};
    default:
      return state;
  }
};


