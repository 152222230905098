import {
	LOADING_REQUEST,
	LOADING_SUCCESS,
	RESUME_LOADING_REQUEST,
	RESUME_LOADING_SUCCESS,
	EDITOR_LOADING_REQUEST,
	EDITOR_LOADING_SUCCESS,
	EDITOR_LOADING_FAIL
} from "../Constants/LoadingConstants";

const initialState = {
	loading: false,
};

export const loadingReducers = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_REQUEST:
			return { loading: action.loading };
		case LOADING_SUCCESS:
			return { loading: action.loading };
		default:
			return state;
	}
};

export const resumeLoadingReducers = (state = initialState, action) => {
	switch (action.type) {
		case RESUME_LOADING_REQUEST:
			return { loading: action.loading };
		case RESUME_LOADING_SUCCESS:
			return { loading: action.loading };
		default:
			return state;
	}
};

export const editorLoadingReducers = (state = initialState, action) => {
	switch (action.type) {
		case EDITOR_LOADING_REQUEST:
			return { loading: action.loading };
		case EDITOR_LOADING_SUCCESS:
			return { loading: action.loading };
		case EDITOR_LOADING_FAIL:
			return { loading: action.loading };
		default:
			return state;
	}
};
