import { TO_ADD, TO_UPDATE } from "../Constants/EditorConstants";
const initialState = {
  hr_name: "",
  companyName: "",
  companyAddress: "",
  city: "",
  date: "",
};
export const toReducers = (state = initialState, action) => {
  switch (action.type) {
    case TO_ADD:
      return action.payload;
    case TO_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
