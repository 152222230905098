export const RESUME_CREATE_REQUEST = "RESUME_CREATE_REQUEST";
export const RESUME_CREATE_SUCCESS = "RESUME_CREATE_SUCCESS";
export const RESUME_CREATE_FAIL = "RESUME_CREATE_FAIL";
export const RESUME_CREATE_RESET = "RESUME_CREATE_RESET";

export const GET_RESUME_REQUEST = "GET_RESUME_REQUEST";
export const GET_RESUME_SUCCESS = "GET_RESUME_SUCCESS";
export const GET_RESUME_FAIL = "GET_RESUME_FAIL";

export const TEMP_RESUME_CREATE_SUCCESS = "TEMP_RESUME_CREATE_SUCCESS";
export const TEMP_RESUME_CREATE_FAIL = "TEMP_RESUME_CREATE_FAIL";
export const TEMP_RESUME_CREATE_RESET = "TEMP_RESUME_CREATE_RESET";

export const TEMP_RESUME_GET_SUCCESS = "TEMP_RESUME_GET_SUCCESS";
export const TEMP_RESUME_GET_FAIL = "TEMP_RESUME_GET_FAIL";
export const TEMP_RESUME_GET_RESET = "TEMP_RESUME_GET_RESET";

export const RESUME_UPDATETOREDIS_REQUEST = "RESUME_UPDATETOREDIS_REQUEST";
export const RESUME_UPDATETOREDIS_SUCCESS = "RESUME_UPDATETOREDIS_SUCCESS";
export const RESUME_UPDATETOREDIS_FAIL = "RESUME_UPDATETOREDIS_FAIL";

export const GET_RESUMEBYID_REQUEST = "GET_RESUMEBYID_REQUEST";
export const GET_RESUMEBYID_SUCCESS = "GET_RESUMEBYID_SUCCESS";
export const GET_RESUMEBYID_FAIL = "GET_RESUMEBYID_FAIL";
export const GET_RESUMEBYID_RESET = "GET_RESUMEBYID_RESET";

export const RESUME_UPDATE_REQUEST = "RESUME_UPDATE_REQUEST";
export const RESUME_UPDATE_SUCCESS = "RESUME_UPDATE_SUCCESS";
export const RESUME_UPDATE_FAIL = "RESUME_UPDATE_FAIL";
export const RESUME_UPDATE_RESET = "RESUME_UPDATE_RESET";

export const RESUME_DUPLICATE_REQUEST = "RESUME_DUPLICATE_REQUEST";
export const RESUME_DUPLICATE_SUCCESS = "RESUME_DUPLICATE_SUCCESS";
export const RESUME_DUPLICATE_FAIL = "RESUME_DUPLICATE_FAIL";
export const RESUME_DUPLICATE_RESET = "RESUME_DUPLICATE_RESET";

export const RESUME_DELETE_REQUEST = "RESUME_DELETE_REQUEST";
export const RESUME_DELETE_SUCCESS = "RESUME_DELETE_SUCCESS";
export const RESUME_DELETE_FAIL = "RESUME_DELETE_FAIL";
export const RESUME_DELETE_RESET = "RESUME_DELETE_RESET";

export const RESUME_DOWNLOAD_REQUEST = "RESUME_DOWNLOAD_REQUEST";
export const RESUME_DOWNLOAD_SUCCESS = "RESUME_DOWNLOAD_SUCCESS";
export const RESUME_DOWNLOAD_FAIL = "RESUME_DOWNLOAD_FAIL";

export const RESUME_DUPLICATE_TITLE_REQUEST = "RESUME_DUPLICATE_TITLE_REQUEST";
export const RESUME_DUPLICATE_TITLE_SUCCESS = "RESUME_DUPLICATE_TITLE_SUCCESS";
export const RESUME_DUPLICATE_TITLE_FAIL = "RESUME_DUPLICATE_TITLE_FAIL";
export const RESUME_DUPLICATE_TITLE_RESET = "RESUME_DUPLICATE_TITLE_RESET";

export const RESUME_NAME_CHANGE_REQUEST = "RESUME_NAME_CHANGE_REQUEST";
export const RESUME_NAME_CHANGE_SUCCESS = "RESUME_NAME_CHANGE_SUCCESS";
export const RESUME_NAME_CHANGE_FAIL = "RESUME_NAME_CHANGE_FAIL";

export const RESUME_LAST_UPDATED_AT = "RESUME_LAST_UPDATED_AT";

export const RESUME_PREVIEW_REQUEST = "RESUME_PREVIEW_REQUEST";
export const RESUME_PREVIEW_SUCCESS = "RESUME_PREVIEW_SUCCESS";
export const RESUME_PREVIEW_FAIL = "RESUME_PREVIEW_FAIL";
export const RESUME_PREVIEW_RESET = "RESUME_PREVIEW_RESET";

export const ADMIN_LIST_REQUEST = "ADMIN_LIST_REQUEST";
export const ADMIN_LIST_SUCCESS = "ADMIN_LIST_SUCCESS";
export const ADMIN_LIST_FAIL = "ADMIN_LIST_FAIL";

export const RESUME_IMAGE_REQUEST = "RESUME_IMAGE_REQUEST";
export const RESUME_IMAGE_SUCCESS = "RESUME_IMAGE_SUCCESS";
export const RESUME_IMAGE_FAIL = "RESUME_IMAGE_FAIL";

export const RESUME_DATA_UPDATE_SUCCESS = "RESUME_DATA_UPDATE_SUCCESS";
export const RESUME_DATA_UPDATE_FAIL = "RESUME_DATA_UPDATE_FAIL";

export const GET_RESUME_HISTORY_SUCCESS = "GET_RESUME_HISTORY_SUCCESS";
export const GET_RESUME_HISTORY_FAIL = "GET_RESUME_HISTORY_FAIL";
